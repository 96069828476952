<h6 class="header-title mb-0 pb-3">Stats</h6>
<div class="row">
  <a class="col-md-6 col-xl-3" [routerLink]="['/dash','objects']">
    <div class="card">
      <div class="media px-3 py-4 border-bottom">
        <div class="media-body">
          <h4 class="mt-0 mb-1 font-size-22 font-weight-normal">{{stats.objects}}</h4>
          <span class="text-muted">Vehicles</span>
        </div>
        <i-feather name="truck" class="align-self-center icon-dual icon-lg"></i-feather>
      </div>
    </div>
  </a>
  <a class="col-md-6 col-xl-3" [routerLink]="['/dash','drivers']">
    <div class="card">
      <div class="media px-3 py-4 border-bottom">
        <div class="media-body">
          <h4 class="mt-0 mb-1 font-size-22 font-weight-normal">{{stats.drivers}}</h4>
          <span class="text-muted">Drivers</span>
        </div>
        <i-feather name="users" class="align-self-center icon-dual icon-lg"></i-feather>
      </div>
    </div>
  </a>
  <a class="col-md-6 col-xl-3" [routerLink]="['/dash','events']">
    <div class="card">
      <div class="media px-3 py-4 border-bottom">
        <div class="media-body">
          <h4 class="mt-0 mb-1 font-size-22 font-weight-normal">{{stats.events}}</h4>
          <span class="text-muted">Total Events</span>
        </div>
        <i-feather name="bell" class="align-self-center icon-dual icon-lg"></i-feather>
      </div>
    </div>
  </a>
  <a class="col-md-6 col-xl-3" [routerLink]="['/dash','routes']">
    <div class="card">
      <div class="media px-3 py-4 border-bottom">
        <div class="media-body">
          <h4 class="mt-0 mb-1 font-size-22 font-weight-normal">{{stats.routes}}</h4>
          <span class="text-muted">Routes</span>
        </div>
        <i-feather name="shuffle" class="align-self-center icon-dual icon-lg"></i-feather>
      </div>
    </div>
  </a>

</div>
<h6 class="header-title mb-0 pb-3">Charts</h6>
<br/>
<div *ngIf="objects.length>0" class="row" style="margin-left: 10px;">
  <div class="card wisd-chart">
    <canvas chartType="pie" [data]="[this.data.online,this.data.inactive]"
            [labels]="['Online Vehicles','Offline Vehicles']" [options]="options"
            baseChart></canvas>
  </div>
  <div class="card wisd-chart">
    <canvas chartType="pie" [data]="[this.data.moving,this.data.standby]"
            [labels]="['Moving Vehicles','Standby Vehicles']" [options]="options"
            baseChart></canvas>
  </div>
  <div class="card wisd-chart">
    <canvas chartType="pie" [data]="[stats.assignedDrivers,stats.unassignedDrivers]"
            [labels]="['Assigned Drivers','Unassigned Drivers']" [options]="options"
            baseChart></canvas>
  </div>

</div>
<div style="display: block;">
  <canvas baseChart
          width="90px"
          height="220px"
          [datasets]="lineChartData"
          [options]="lineChartOptions"
          [colors]="lineChartColors"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [plugins]="lineChartPlugins"
          [labels]="lineChartLabels">
  </canvas>

</div>

<div *ngIf="objects.length===0">
  Add some vehicles and chart will be shown here !
</div>
