import {Component, Inject, OnInit} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Const} from '../Const';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {SocketService} from '../services/socket.service';
import {DashrequestService} from '../services/dashrequest.service';
import {ToastService} from '../services/toast.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DefaultsService} from '../services/defaults.service';
import {Track} from '../live-tracking/live-tracking.component';
import * as introJs from 'intro.js/intro.js';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  introJS = introJs();

  userData = this.storage.get(Const.TOKEN);
  cost = 'Updating cost...';
  shown = false;
  notifications = []
  unseenNotifications = 0
  // newCost: number;
  newCost = {
    petrol: 100,
    disel: 100
  };
  objects: Track[];
  data = {
    online: 10,
    moving: 11,
    standby: 20,
    inactive: 30
  };

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private router: Router,
              private authService: AuthService, private socketService: SocketService,
              public ngbModal: NgbModal,
              private defaultService: DefaultsService,
              public httpService: DashrequestService, private toastService: ToastService) {

  }

  parseStats() {
    this.data.online = this.objects.filter(x => (Date.now() - Number(x.lastPosition.serverTime)) <= (Number(this.defaultService.getData('defaultOnlineTime')) * 1000)).length;
    this.data.moving = this.objects.filter(x => (Date.now() - Number(x.lastPosition.serverTime)) <= (Number(this.defaultService.getData('defaultOnlineTime')) * 1000) && x.lastPosition.speed >= 1).length;
    this.data.standby = this.objects.filter(x => (Date.now() - Number(x.lastPosition.serverTime)) <= (Number(this.defaultService.getData('defaultOnlineTime')) * 1000) && x.lastPosition.speed < 1).length;
    this.data.inactive = this.objects.filter(x => x.lastPosition.serverTime === '0').length;

  }

  ngOnInit(): void {
    this.httpService.objects.subscribe(objects => {
      this.objects = objects;
      this.parseStats();
    });
    this.httpService.getFuelCost()
      .subscribe(d => {
        this.newCost = d;
        this.cost = 'Today\'s fuel cost : रू ' + d.cost;
      });
    this.httpService.getNotifications()
      .subscribe(({notifications, unseenNotifications}) => {
        this.notifications = notifications
        this.unseenNotifications = unseenNotifications
      })
    this.socketService.connect();
    this.socketService.on('message', (data) => {
      // this.toastService.show('Socket Received ' + data.type);
      switch (data.type) {
        case 'OBJ_UPDATE':
          this.httpService.updateData(data.msg);
          break;
        case 'EVENT_UPDATE':
          this.toastService.show(data.msg.msg, {classname: 'bg-success text-light', delay: 40000});
          break;
        case 'NOTIFICATION_ADD':
          this.toastService.show(data.text);
          this.notifications = [data, ...this.notifications]
          this.unseenNotifications += 1
          break;
      }
    });
  }

  markAsRead() {
    this.httpService.markAllAsRead()
      .subscribe(d => {
        this.unseenNotifications = 0;
      })

  }

  logout() {
    this.socketService.disconnect();
    this.authService.logout().subscribe(
      data => {
        this.storage.remove(Const.TOKEN);
        this.storage.set(Const.SUCCESS_MSG, 'Logged out successfully');
        this.router.navigate(['/login']).then();
      }, err => {
        this.storage.remove(Const.TOKEN);
        this.storage.set(Const.SUCCESS_MSG, 'Logged out successfully');
        this.router.navigate(['/login']).then();
      }
    );
  }

  parseRole(role: number) {
    this.parseStats();
    return Const.ROLES[role];
  }

  changeCost() {
    this.ngbModal.dismissAll();
    this.httpService.updateFuel(this.newCost)
      .subscribe(data => {
        this.cost = 'Today\'s fuel cost : रू ' + this.newCost;
      });
  }

  toggle() {
    this.shown = !this.shown;
  }
}
