import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Const} from '../Const';
import {Track} from '../live-tracking/live-tracking.component';
import {BehaviorSubject} from 'rxjs';
import * as XLSX from 'xlsx'

export interface Positions {
  servertime: string;
  devicetime: string;
  latitude: number;
  course: number;
  longitude: number;
  speed: number;
  altitude: number;
  attributes: string;
}


@Injectable({
  providedIn: 'root'
})
export class DashrequestService {
  private objectsSource = new BehaviorSubject<Track[]>([]);
  objects = this.objectsSource.asObservable();

  // objects: Track[] = [];

  constructor(private http: HttpClient) {
  }

  exportTable(documentId) {
    let element = document.getElementById(documentId);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "export_" + Date.now() + ".xlsx");
  }

  loadObjects() {
    return this.http.get<Track[]>(Const.LOAD_OBJECTS_URL);
  }

  getCurrentObjectValue() {
    return this.objectsSource.getValue();
  }

  getFuelCost() {
    return this.http.get<any>(Const.FUELCOST + Date.now());
  }

  getNotifications() {
    return this.http.get<any>(Const.GET_NOTIFICATIONS);
  }

  markAllAsRead() {
    return this.http.get<any>(Const.GET_NOTIFICATIONS + '/markallasread')
  }

  addObject(data) {
    return this.http.post<Track>(Const.ADD_OBJECT_URL, data);
  }

  updateObject(id: string, push) {
    return this.http.post<Track>(Const.UPDATE_OBJECT + id, push);
  }

  objectHistory(id: string, time) {
    return this.http.get<Positions[]>(`${Const.HISTORY_OBJECT}${id}/${time}`);
  }

  singleObjectHisory(imei: string, to: number, from: number) {
    return this.http.get<Positions[]>(`${Const.HISTORY_CUSTOM_OBJECT}${imei}/${to}/${from}`);
  }

  changeAll(data: Track[]) {
    this.objectsSource.next(data);
  }

  updateData(data: Track) {
    // tslint:disable-next-line:variable-name
    let _lastObjects = this.objectsSource.getValue();
    if (_lastObjects.filter(x => x.imei === data.imei).length > 0) {
      _lastObjects = _lastObjects.map(s => {
        if (s.imei === data.imei) {
          const pos = s.lastPosition.latLng.split(':');
          s.lastLocations.push({
            lat: pos[0],
            lon: pos[1]
          });
          data.lastLocations = s.lastLocations;
          return data;
        } else {
          return s;
        }
      });
    } else {
      _lastObjects.push(data);
    }
    this.objectsSource.next(_lastObjects);
  }

  removeObject(edit: Track) {
    this.objectsSource.next(this.objectsSource.getValue().filter(x => x.imei !== edit.imei));
  }

  deleteObject(id: string) {
    return this.http.get<any>(Const.DELETE_OBJECT + id);
  }

  getDrivers() {
    return this.http.get<any>(Const.DRIVERS);
  }

  addDriver(addForm: any) {
    return this.http.post<any>(Const.ADD_DRIVER, addForm);
  }

  editDriver(addForm: any, id: string) {
    return this.http.post<any>(Const.EDIT_DRIVER + id, addForm);
  }

  mergeKeys(others: any[]) {
    const x = others;
    const objects = [];
    x.forEach(s => {
      const w = {};
      w[s[0]] = s[1];
      objects.push(w);
    });
    return objects;
  }

  fetchKeys(others: any[]) {
    return others?others.map(s => {
      return Object.keys(s).map(s1 => [s1, s[s1]])[0];
    }):[];
  }

  getEvents() {
    return this.http.get<any>(Const.EVENTS);
  }

  getReports() {
    return this.http.get<any>(Const.REPORTS);
  }

  addEvent(addForm) {
    return this.http.post<any>(Const.ADD_EVENT, addForm);
  }

  editEvent(id: any, edit: any) {
    return this.http.post<any>(Const.EDIT_EVENT + id, edit);
  }

  deleteEvent(id: any) {
    return this.http.get(Const.DELETE_EVENT + id);
  }

  getRoutes() {
    return this.http.get(Const.ROUTES);
  }

  addRoute(addForm) {
    return this.http.post<any>(Const.ROUTES_ADD, addForm);
  }

  deleteRoute(id: any) {
    return this.http.get(Const.DELETE_ROUTE + id);
  }

  assignObject(selectedObject: string, param2: { driver: string }) {
    return this.http.post(Const.ASSIGN_OBJECT + selectedObject, param2);
  }

  getZones() {
    return this.http.get<any>(Const.ZONE);
  }

  addZone(addForm: any) {
    return this.http.post<any>(Const.ZONE_ADD, addForm);
  }

  editZone(id: any, edit: any) {
    return this.http.post<any>(Const.ZONE_EDIT + id, edit);
  }

  deleteZone(id: any) {
    return this.http.get(Const.ZONE_DELETE + id);
  }

  editRoute(id: any, edit: any) {
    return this.http.post<any>(Const.ROUTES_EDIT + id, edit);
  }

  addSubAccount(addForm: any) {
    return this.http.post<any>(Const.SUBACCOUNT_ADD, addForm);
  }

  getSubAccounts() {
    return this.http.get<any>(Const.SUBACCOUNT);
  }

  parseDate(serverTime: string) {
    try {
      if (serverTime === '0') {
        return 'No Data';
      }
      const x = new Date(Number(serverTime));
      // return x.toISOString().split('T')[0];
      return x.toLocaleDateString() + ' ' + x.toLocaleTimeString();
    } catch (e) {
      return serverTime;
    }

  }

  giveTimeStamp(disablingDate: string) {
    return new Date(disablingDate).getTime().toString();
  }

  updateSubAccount(id: any, edit: any) {
    return this.http.post<any>(Const.SUBACCOUNT_EDIT + id, edit);
  }

  getStats() {
    return this.http.get<any>(Const.STATS);
  }

  getAlerts() {
    return this.http.get<any>(Const.ALERTS);
  }

  updateFuel(param: any) {
    return this.http.post<any>(Const.UPDATE_FUEL, param);
  }

  addReport(addForm) {
    return this.http.post(Const.ADD_REPORT, addForm, {responseType: 'blob'});
  }

  previewReport(addForm) {
    return this.http.post(Const.ADD_REPORT, addForm, {responseType: 'text'});
  }

  saveReport(addForm) {
    return this.http.post(Const.SAVE_REPORT, addForm);
  }

  // tslint:disable-next-line:variable-name
  deleteReport(_id: any) {
    return this.http.get(Const.DELETE_REPORT + _id);
  }

}
