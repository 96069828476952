import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Const} from '../Const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private http: HttpClient) {
  }

  login = (data) => {
    return this.http.post<any>(Const.LOGIN_URL, data);
  };
  register = (data) => {
    return this.http.post<any>(Const.REGISTER_URL, data);
  };
  logout = () => {
    return this.http.get<any>(Const.LOGOUT_URL);
  };
  requestDemo = (data) => {
    return this.http.post<any>(Const.REQUEST_DEMO, data)
  }

}
