<div class="account-pages my-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <div class="card">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-6 p-5">
                <div class="mx-auto mb-5">
                  <a href="index.html">
                    <img src="assets/images/logo.png" alt="" height="24"/>
                    <h3 class="d-inline align-middle ml-1 text-logo">Hamro Track</h3>
                  </a>
                </div>
                <div *ngIf="em!=''&&em!=null" class="alert alert-danger">{{em}}</div>

                <h6 class="h5 mb-0 mt-4">Create your account</h6>
                <p class="text-muted mt-1 mb-4">Create a free account and start using hamrotrack</p>

                <form *ngIf="!submitted" (ngSubmit)="onFormSubmit(registerRequest)" novalidate #form="ngForm"
                      class="authentication-form">
                  <div class="form-group">
                    <label class="form-control-label">Email Address</label>
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <i class="fa fa-at" data-feather="mail"></i>
                                                        </span>
                      </div>
                      <input [class.is-invalid]="email.touched&&email.invalid" type="email" class="form-control"
                             required
                             pattern=".*@.*\.[aA-zZ]+" name="email"
                             [(ngModel)]="registerRequest.email" #email="ngModel" placeholder="Enter your email">
                      <div class="invalid-tooltip">Please enter a valid mail</div>
                    </div>
                  </div>

                  <div class="form-group mt-4">
                    <label class="form-control-label">Name</label>
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <i class="fa fa-user-circle"></i>
                                                        </span>
                      </div>
                      <input [class.is-invalid]="name.touched&&name.invalid" required name="name"
                             [(ngModel)]="registerRequest.name" #name="ngModel" type="name"
                             class="form-control" id="name"
                             placeholder="Enter your name">
                      <div class="invalid-tooltip">Please enter a valid name</div>
                    </div>
                  </div>

                  <div class="form-group mt-4">
                    <label class="form-control-label">Password</label>
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                              <span class="input-group-text">
                                                <i class="fa fa-lock"></i>
                                                  </span>
                      </div>
                      <input [class.is-invalid]="password.touched&&password.invalid" required name="password"
                             [(ngModel)]="registerRequest.password" #password="ngModel" type="password"
                             class="form-control" id="password"
                             placeholder="Enter your password">
                      <div class="invalid-tooltip">Please enter a valid password</div>

                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input"
                             id="checkbox-signin" checked>
                      <label class="custom-control-label" for="checkbox-signin">I accept all the terms and
                        conditions</label>
                    </div>
                  </div>

                  <div class="form-group mb-0 text-center">
                    <button [disabled]="form.form.invalid||submitted" class="btn btn-primary btn-block" type="submit">
                      Register
                    </button>
                  </div>
                </form>
                <div class="py-3 text-center"><span class="font-size-16 font-weight-bold">Or</span></div>
                <div class="row">
                  <div class="col-6">
                    <a href="" class="btn btn-white"><i class='uil uil-google icon-google mr-2'></i>With Google</a>
                  </div>
                  <div class="col-6 text-right">
                    <a href="" class="btn btn-white"><i class='uil uil-facebook mr-2 icon-fb'></i>With Facebook</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-none d-md-inline-block">
                <div class="auth-page-sidebar">
                  <div class="overlay"></div>
                  <div class="auth-user-testimonial">
                    <p class="font-size-24 font-weight-bold text-white mb-1">I simply love it!</p>
                    <p class="lead">"It's a elegent templete. I love it very much!"</p>
                    <p>- Admin User</p>
                  </div>
                </div>
              </div>
            </div>

          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">Already have account? <a [routerLink]="['/login']"
                                                            class="text-primary font-weight-bold ml-1">Login</a></p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>

