import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DashrequestService} from '../services/dashrequest.service';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  page = 1;
  preview = false;
  reports = [];
  edit: any;
  pageSize = 6;
  collectionSize = 0;
  search = '';
  objects = [];
  html: string;
  selectedObjects = [];


  date = new Date();
  addForm = {
    name: '',
    type: 'gI',
    timeOffset: new Date().getTimezoneOffset(),
    bTime: this.parseDate(-1),
    aTime: this.parseDate(),
    objects: [],
    generateTime: this.parseDate(),
    repetition: '0',
    /*0 - For PDF 1- HTML 2- XLS */
    format: '1',
    mailToSend: ''
  };
  reportType = {
    gI: 'General Information'
  };
  repitionType = ['Generate Now', 'Daily', 'Weekly', 'Monthly', 'Specific Date', 'Preview'];

  get pagedobjects() {
    this.collectionSize = this.reports.length;
    return this.reports
      .filter(s => this.search === '' || s.name.toLowerCase().includes(this.search.toLowerCase()))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  parseDate(increase = 0) {
    const local = new Date();
    local.setDate(local.getDate() + increase);
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

  constructor(public ngbModal: NgbModal, private httpService: DashrequestService) {

  }

  ngOnInit(): void {
    this.httpService.getReports()
      .subscribe(data => {
        this.collectionSize = data.length;
        this.reports = data;
        this.objects = [{
          name: 'All Objects',
          imei: 'ALL'
        }, ...Object.create(this.httpService.getCurrentObjectValue().map(s => {
          return {name: s.name, imei: s.imei};
        }))];
      });
  }

  rightClick() {

  }

  editObject(item: any) {
    console.log(item);

  }

  deleteNow(item: any) {
    this.edit = item;

  }


  submitAddForm(report: TemplateRef<any>) {
    this.addForm.objects = this.selectedObjects.map(s => s.imei);
    this.addForm.aTime = this.httpService.giveTimeStamp(this.addForm.aTime);
    this.addForm.bTime = this.httpService.giveTimeStamp(this.addForm.bTime);
    this.ngbModal.dismissAll();
    if (this.addForm.repetition === '0') {
      this.httpService.addReport(this.addForm)
        .subscribe(data => {
          saveAs(data, `${new Date()}.${this.addForm.format === '0' ? 'pdf' : this.addForm.format === '1' ? 'html' : 'xls'}`);
          this.addForm.bTime = this.parseDate(-1);
          this.addForm.aTime = this.parseDate();
        }, err => {
          this.addForm.bTime = this.parseDate(-1);
          this.addForm.aTime = this.parseDate();
        });

    } else if (this.addForm.repetition === '5') {
      this.addForm.repetition = '1';
      this.httpService.previewReport(this.addForm)
        .subscribe(d => {
          this.html = String(d);
          this.ngbModal.open(report, {size: 'xl'});
          this.addForm.bTime = this.parseDate(-1);
          this.addForm.aTime = this.parseDate();
        });
    } else {
      this.httpService.saveReport(this.addForm)
        .subscribe(s => {
          this.reports = [s, ...this.reports];
        });
    }
  }

  deleteObject() {
    this.ngbModal.dismissAll();
    this.httpService.deleteReport(this.edit._id)
      .subscribe(d => {
        this.reports = this.reports.filter(x => x._id !== this.edit._id);

      });

  }

  Number(repetition: any) {
    return Number(repetition);
  }

  Date(lastReported: number) {
    return this.httpService.parseDate(lastReported.toString());
  }
}
