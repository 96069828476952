import {Component, OnInit} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Track} from '../live-tracking/live-tracking.component';

export interface Drivers {
  isActive: boolean;
  others: any;
  name: string;
  age: number;
  description: string;
  email: string;
  phone: string;
  objectAssigned: any;
  id: string;
}

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {

  addForm = {
    others: [],
    Id: '',
    phone: '',
    email: '',
    name: ''
  };

  drivers: Drivers[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  search = '';
  edit: Drivers;
  except: string;
  selectedObject = '';

  constructor(public httpService: DashrequestService, public ngbModal: NgbModal) {
  }

  ngOnInit(): void {
    this.httpService.getDrivers()
      .subscribe(data => {
        this.collectionSize = data.length;
        this.drivers = data;
      });

  }

  rightClick() {
    return false;
  }

  editObject(item) {
    this.edit = Object.create(item);
    if (this.edit.objectAssigned.length > 0) {
      this.except = this.edit.objectAssigned.filter(x1 => x1.removedAt === 123456789)[0].objectId;
    } else {
      this.selectedObject = '';
      this.except = '';
    }
    this.edit.others = this.httpService.fetchKeys(this.edit.others);
  }

  deleteNow(item) {

  }

  deleteObject() {

  }

  submitAddForm() {
    this.addForm.others = this.httpService.mergeKeys(this.addForm.others);
    this.httpService.addDriver(this.addForm)
      .subscribe(data => {
        this.drivers.push(data);
        this.addForm.others = [];
        this.addForm.email = '';
        this.addForm.name = '';
        this.addForm.phone = '';
        this.addForm.Id = '';
        this.ngbModal.dismissAll();
      }, error => console.log(error));

  }

  get pagedobjects() {
    this.collectionSize = this.drivers.length;
    return this.drivers
      .filter(s => this.search === '' || s.phone.toLowerCase().includes(this.search.toLowerCase())
        || s.email.toLowerCase().includes(this.search.toLowerCase())
        || s.name.toLowerCase().includes(this.search.toLowerCase()) ||
        JSON.stringify(s.others).toLowerCase().includes(this.search.toLowerCase()))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  addOtherInfo(s: string = 'A') {
    if (s === 'E') {
      this.edit.others.push(['', '']);
      return;
    }
    this.addForm.others.push(['', '']);
  }

  deleteOtherInfo(s: string = 'A') {
    if (s === 'E') {
      this.edit.others = this.edit.others.filter(s1 => s1[0] !== 'wisd_x');
      return;
    }
    this.addForm.others = this.addForm.others.filter(s1 => s1[0] !== 'wisd_x');
  }

  updateForm() {
    this.edit.others = this.httpService.mergeKeys(this.edit.others);
    this.httpService.editDriver(this.edit, this.edit.id)
      .subscribe(data => {
        this.ngbModal.dismissAll();
        this.drivers = this.drivers.map(s => {
          if (s.id === data.id) {
            return data;
          }
          return s;
        });
      });

  }

  assignObject() {
    this.httpService.assignObject(this.selectedObject, {driver: this.edit.id})
      .subscribe(data => {
        this.ngOnInit();
        this.ngbModal.dismissAll();
      });
  }

  parseObject(objectAssigned: any) {
    try {
      return this.httpService.getCurrentObjectValue().filter(x => x.imei ===
        String(objectAssigned.filter(x1 => x1.removedAt === 123456789)[0].objectId))[0].name;
    } catch (e) {
      return 'Not Assigned';
    }

  }

  exceptSome(objects: Track[]) {
    return objects.filter(x => x.imei !== this.except);
  }
}
