<div class="account-pages my-5">
  <div class="container">
    <ng-template #content let-modal class="modal-dialog-scrollable">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Request for demo</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #form1="ngForm">
          <div class="form-group">
            <label>Email</label>
            <input #email1="ngModel" [(ngModel)]="demoRequest.email"
                   required type="text" class="form-control" name="email" aria-describedby="emailHelp"
                   placeholder="Enter your email">
          </div>
          <div class="form-group">
            <label>Name</label>
            <input #name="ngModel"
                   [(ngModel)]="demoRequest.name"
                   required type="text" class="form-control" name="name" aria-describedby="emailHelp"
                   placeholder="Enter your name">
          </div>
          <div class="form-group">
            <label>Phone Number</label>
            <input #phone="ngModel"
                   [(ngModel)]="demoRequest.phone"
                   required type="text"
                   minlength="10"
                   maxlength="10"
                   class="form-control" name="phone" aria-describedby="emailHelp"
                   placeholder="Enter your phone number">
          </div>
          <div class="form-group">
            <label>Message</label>
            <input #message="ngModel"
                   [(ngModel)]="demoRequest.message"
                   required type="text" class="form-control" name="message" aria-describedby="emailHelp"
                   placeholder="Enter your message">
          </div>

          <div class="form-group">
            <re-captcha (resolved)="resolved($event)" siteKey="6LcqjuwZAAAAAFYh1Uuw7vM38h1_8jiNEjiwxwt3"></re-captcha>
          </div>

        </form>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
        <button (click)="submitDemoForm()" [disabled]="form1.form.invalid || this.demoRequest.recaptchaKey.trim()===''"
                class="btn btn-outline-secondary"><i
          class="fa fa-plus-circle"></i>
          Submit
        </button>

      </div>
    </ng-template>

    <div class="row justify-content-center">
      <div class="col-xl-10">
        <div class="card">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-6 p-5">
                <div class="mx-auto mb-5">
                  <a href="/">
                    <img src="assets/icons/android-icon-144x144.png" alt="" height="24"/>
                    <h3 class="d-inline align-middle ml-1 text-logo">Sawari</h3>
                  </a>
                </div>
                <div *ngIf="em!=''&&em!=null" class="alert alert-danger">{{em}}</div>
                <div *ngIf="sm!=null" class="alert alert-success">{{sm}}</div>

                <h6 class="h5 mb-0 mt-4">Welcome back!</h6>
                <p class="text-muted mt-1 mb-4">Enter your email address and password to
                  access admin panel.</p>

                <form *ngIf="!submitted" (ngSubmit)="onFormSubmit(loginRequest)" novalidate #form="ngForm"
                      class="authentication-form">
                  <div class="form-group">
                    <label class="form-control-label">Email Address</label>
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-at"></i>
                        </span>
                      </div>
                      <input [class.is-invalid]="email.touched&&email.invalid" type="email" class="form-control"
                             required pattern=".*@.*\.[aA-zZ]+" name="email" [(ngModel)]="loginRequest.email"
                             #email="ngModel" placeholder="Enter your email">
                      <div class="invalid-tooltip">Please enter a valid mail</div>
                    </div>
                  </div>

                  <div class="form-group mt-4">
                    <label class="form-control-label">Password</label>
                    <a class="float-right text-muted text-unline-dashed ml-1">Forgot your
                      password?</a>
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-lock"></i>
                        </span>
                      </div>
                      <input [class.is-invalid]="password.touched&&password.invalid" required name="password"
                             [(ngModel)]="loginRequest.password" #password="ngModel" type="password"
                             class="form-control"
                             id="password" placeholder="Enter your password">
                      <div class="invalid-tooltip">Please enter a valid password</div>

                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="checkbox-signin" checked>
                      <label class="custom-control-label" for="checkbox-signin">Remember
                        me</label>
                    </div>
                  </div>

                  <div class="form-group mb-0 text-center">
                    <button [disabled]="form.form.invalid||submitted" class="btn btn-primary btn-block" type="submit">
                      Log In
                    </button>
                  </div>
                </form>
                <!-- <div class="py-3 text-center"><span class="font-size-16 font-weight-bold">Or</span></div> -->
                <!-- <div class="row">
                  <div class="col-6">
                    <a class="btn btn-white"><i class='uil uil-google icon-google mr-2'></i>With Google</a>
                  </div>
                  <div class="col-6 text-right">
                    <a class="btn btn-white"><i class='uil uil-facebook mr-2 icon-fb'></i>With Facebook</a>
                  </div>
                </div> -->
              </div>
              <div class="col-lg-6 d-none d-md-inline-block">
                <div class="auth-page-sidebar">
                  <div class="overlay"></div>
                  <div class="auth-user-testimonial">
                    <p class="font-size-24 font-weight-bold text-white mb-1">I simply love it!</p>
                    <p class="lead">"It's a elegent templete. I love it very much!"</p>
                    <p>- Admin User</p>
                  </div>
                </div>
              </div>
            </div>

          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">To request a demo
              <button (click)="showDialog()"
                      class="text-primary font-weight-bold ml-1">Click Here
              </button>
            </p>
          </div>
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
