import {Component, Inject, OnInit} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private httpService: DashrequestService, @Inject(AppComponent) private parent: AppComponent) {
  }

  ngOnInit(): void {
    this.httpService.loadObjects().subscribe(
      data => {
        this.httpService.changeAll(data.map(s => {
          s.lastLocations = [];
          return s;
        }));
      }, error => {
        console.log(error);

      }
    );
  }

}
