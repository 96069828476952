import {Component, OnInit} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {DefaultsService} from '../services/defaults.service';
import {Track} from '../live-tracking/live-tracking.component';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Label} from 'ng2-charts';

@Component({
    selector: 'app-dash',
    templateUrl: './dash.component.html',
    styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit {
    stats = {
        objects: 0,
        drivers: 0,
        assignedDrivers: 0,
        unassignedDrivers: 0,
        events: 0,
        routes: 0
    };
    data = {
        online: 0,
        moving: 0,
        standby: 0,
        inactive: 0
    };

    options = {
        responsive: true
    };

    objects: Track[] = null;

    public lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false
    };

    public lineChartData: ChartDataSets[] = [
        {data: [65, 59, 80, 81, 56, 55, 40], label: 'Vehicle Information'}
    ];
    public lineChartLabels = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July'
    ];
    public lineChartColors = [
        {
            borderColor: 'black',
            backgroundColor: 'rgba(255,0,0,0.3)'
        }
    ];
    public lineChartLegend = true;
    public lineChartType = 'line';
    public lineChartPlugins = [];


    constructor(public httpService: DashrequestService, private defaultService: DefaultsService) {
    }

    ngOnInit(): void {
        this.httpService.objects.subscribe(data => {
            this.data.online = data.filter(x => (Date.now() - Number(x.lastPosition.serverTime)) <= (Number(this.defaultService.getData('defaultOnlineTime')) * 1000)).length;
            this.data.moving = data.filter(x => (Date.now() - Number(x.lastPosition.serverTime)) <= (Number(this.defaultService.getData('defaultOnlineTime')) * 1000) && x.lastPosition.speed >= 1).length;
            this.data.standby = data.filter(x => (Date.now() - Number(x.lastPosition.serverTime)) <= (Number(this.defaultService.getData('defaultOnlineTime')) * 1000) && x.lastPosition.speed < 1).length;
            this.data.inactive = data.filter(x => x.lastPosition.serverTime === '0').length;
            this.objects = data;
        });


        this.httpService.getStats()
            .subscribe(stats => {
                this.stats = stats;

            });

    }


    parseDataSets() {
        return this.objects.map(s => s.attributes.totalDistance);
    }

    parseLabels() {
        return this.objects.map(s => s.name);
    }
}
