<ng-template #assign let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Assign a Object to {{edit.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form novalidate #form3="ngForm">
      <div class="form-group">
        <label>Name</label>
        <select #object="ngModel" name="object" [(ngModel)]="selectedObject" class="form-control">
          <option value="" hidden selected>Select a option</option>
          <option *ngFor="let x of exceptSome(httpService.getCurrentObjectValue())" value="{{x.id}}">{{x.name}}</option>
        </select>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button class="btn-primary btn" (click)="assignObject()" [disabled]="selectedObject==''||form3.form.invalid||form3.form.pristine">Assign
    </button>
  </div>
</ng-template>
<ng-template #content let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Add a Driver</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form novalidate #form="ngForm">
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="addForm.name"
               required type="text"
               class="form-control" placeholder="Enter object name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Phone No</label>
        <input pattern="9?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?"
               [class.is-invalid]="sim.touched&&sim.invalid" name="sim" #sim="ngModel"
               [(ngModel)]="addForm.phone"
               required type="number"
               class="form-control" placeholder="Enter simcard number">
        <small *ngIf="sim.touched&&sim.invalid" class="alert-danger p-1">Enter a valid number</small>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input pattern=".*@.*\..*"
               [class.is-invalid]="email.touched&&email.invalid" name="email" #email="ngModel"
               [(ngModel)]="addForm.email"
               required type="email"
               class="form-control" placeholder="Enter email">
        <small *ngIf="email.touched&&email.invalid" class="alert-danger p-1">Enter a valid email</small>
      </div>
      <div class="form-group">
        <label>Other Info</label>
        <br>
        <div style="margin-top: 2px;" class="row" *ngFor="let item of addForm.others;let i=index;">
          <div class="col-sm-5">
            <input name="attrib_{{i}}"
                   [(ngModel)]="item[0]"
                   required
                   type="text"
                   class="form-control" placeholder="Enter field">
          </div>
          <div class="col-sm-6">
            <input
              [(ngModel)]="item[1]"
              required type="text"
              name="value_{{i}}"
              #v_{{i}}
              class="form-control" placeholder="Enter value">
          </div>
          <button (click)="item[0]='wisd_x';deleteOtherInfo()" class="btn btn-danger"><i class="fa fa-trash"></i>
          </button>
        </div>
        <br>
        <button type="button" (click)="addOtherInfo()" class="btn btn-primary">Add <i class="fa fa-plus-circle"></i>
        </button>
      </div>


    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="submitAddForm()" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-plus-circle"></i> Add
    </button>

  </div>
</ng-template>
<ng-template #editItem let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Edit {{edit.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form novalidate #form="ngForm">
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="edit.name"
               required type="text"
               class="form-control" placeholder="Enter object name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Phone No</label>
        <input pattern="9?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?"
               [class.is-invalid]="sim.touched&&sim.invalid" name="sim" #sim="ngModel"
               [(ngModel)]="edit.phone"
               required type="number"
               class="form-control" placeholder="Enter simcard number">
        <small *ngIf="sim.touched&&sim.invalid" class="alert-danger p-1">Enter a valid number</small>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input pattern=".*@.*\..*"
               [class.is-invalid]="email.touched&&email.invalid" name="email" #email="ngModel"
               [(ngModel)]="edit.email"
               required type="email"
               class="form-control" placeholder="Enter email">
        <small *ngIf="email.touched&&email.invalid" class="alert-danger p-1">Enter a valid email</small>
      </div>
      <div class="form-group">
        <label>Other Info</label>
        <br>
        <div style="margin-top: 2px;" class="row" *ngFor="let item of edit.others;let i=index;">
          <div class="col-sm-5">
            <input name="attrib_{{i}}"
                   [(ngModel)]="item[0]"
                   required
                   type="text"
                   class="form-control" placeholder="Enter field">
          </div>
          <div class="col-sm-6">
            <input
              [(ngModel)]="item[1]"
              required type="text"
              name="value_{{i}}"
              class="form-control" placeholder="Enter value">
          </div>
          <button (click)="item[0]='wisd_x';deleteOtherInfo('E')" class="btn btn-danger"><i class="fa fa-trash"></i>
          </button>
        </div>
        <br>
        <button type="button" (click)="addOtherInfo('E')" class="btn btn-primary">Add <i class="fa fa-plus-circle"></i>
        </button>
      </div>


    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="updateForm()" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-cloud-upload-alt"></i> Update
    </button>

  </div>
</ng-template>

<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h5 class="card-title mt-0 mb-0 header-title">Drivers</h5>
        <div class="float-sm-right mt-3 mt-sm-0">
          <div class="task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
            <form>
              <div class="input-group">
                <input name="search" [(ngModel)]="search" type="text" class="form-control search-input"
                       placeholder="Search..."/>
                <span class="uil uil-search icon-search"></span>
                <div class="input-group-append">
                  <button (click)="ngbModal.open(content)" class="btn btn-soft-primary" type="button">
                    <i class='uil uil-plus-circle'></i> Add New
                  </button>
                  <button (click)="this.httpService.exportTable('data-table')"
                          class="btn btn-soft-secondary"><i class="fa fa-file-export"></i> Export as Excel Sheet
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table id="data-table" class="table table-hover table-nowrap mb-0">
        <thead>
        <tr>
          <th>S.N</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
          <th scope="col">Status</th>
          <th scope="col">Object Assigned</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr (contextmenu)="rightClick()" *ngFor="let item of pagedobjects;let i=index;">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.email}}</td>
          <td>{{item.phone}}</td>
          <td><span
            class="badge badge-soft-{{item.isActive?'primary':'danger'}}"> {{item.isActive ? 'Active' : 'Inactive'}}</span>
          </td>
          <td>{{parseObject(item.objectAssigned)}}</td>
          <td><a (click)="editObject(item);ngbModal.open(editItem)"><i class="fa fa-edit"></i></a>
            &nbsp;
            <a (click)="editObject(item);ngbModal.open(assign)"><i class="fa fa-car-alt"></i></a>
            &nbsp;
            <a
              (click)="deleteNow(item);">&nbsp;<i
              class="fa fa-{{item.isActive?'times-circle':'check-circle'}}"></i></a></td>
        </tr>
        </tbody>
        <div *ngIf="pagedobjects.length===0" style="padding: 10px;">
          No drivers found.
        </div>

      </table>
    </div>
    <br>
    <div>
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
      </ngb-pagination>
    </div>

  </div> <!-- end card-body-->
</div> <!-- end card-->
<button (click)="ngbModal.open(content)" title="add a route" class="btn-secondary btn btn-rounded"
        style="z-index:123;margin: 10px;position: fixed;bottom: 0;right: 0;"><i
  class="fa fa-plus-circle"></i></button>
