import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DashrequestService} from '../services/dashrequest.service';

@Component({
  selector: 'app-sub-accounts',
  templateUrl: './sub-accounts.component.html',
  styleUrls: ['./sub-accounts.component.css']
})
export class SubAccountsComponent implements OnInit {

  addForm = {
    name: '',
    email: '',
    features: {
      canBeDisabled: false,
      disablingDate: '2029-09-01'
    }
  };
  page = 1;
  edit: any;
  accounts = [];
  pageSize = 6;
  collectionSize = 0;
  search = '';

  constructor(public ngbModal: NgbModal, public httpService: DashrequestService) {
  }

  ngOnInit(): void {
    this.httpService.getSubAccounts()
      .subscribe(data => {
        this.accounts = data;
      });
  }

  get pagedobjects() {
    this.collectionSize = this.accounts.length;
    return this.accounts
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  rightClick() {

  }

  editObject(item: any) {
    this.edit = Object.create(item);
    this.edit.features.disablingDate = this.httpService.parseDate(this.edit.features.disablingDate);
  }

  submitAddForm() {
    this.addForm.features.disablingDate = this.httpService.giveTimeStamp(this.addForm.features.disablingDate);
    this.httpService.addSubAccount(this.addForm)
      .subscribe(data => {
        this.accounts.push(data);
        this.ngbModal.dismissAll();
      });
  }

  updateForm() {
    this.ngbModal.dismissAll();
    this.edit.features = {
      disablingDate: this.httpService.giveTimeStamp(this.edit.features.disablingDate),
      canBeDisabled: this.edit.features.canBeDisabled
    };
    this.httpService.updateSubAccount(this.edit.id, this.edit)
      .subscribe(data => {
        this.accounts = this.accounts.map(s => {
          if (s.id === data.id) {
            return data;
          }
          return s;
        });
      });
  }

  deleteObject() {

  }
}
