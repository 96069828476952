<ng-template #content let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add a User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form novalidate #form="ngForm">
      <div class="custom-control custom-switch mb-2">
        <input id="customSwitch3" type="checkbox" class="custom-control-input" name="isActive"
               [(ngModel)]="addForm.features.canBeDisabled">
        <label class="custom-control-label" for="customSwitch3">Disabled?</label>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="addForm.name"
               required type="text"
               class="form-control" placeholder="Enter user name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input [class.is-invalid]="email.touched&&email.invalid" name="email" #email="ngModel"
               [(ngModel)]="addForm.email"
               required type="email"
               pattern=".*@.*\..*"
               class="form-control" placeholder="Enter user's email'">
        <small *ngIf="email.touched&&email.invalid" class="alert-danger p-1">Enter a valid mail</small>
      </div>
      <div *ngIf="!addForm.features.canBeDisabled" class="form-group">
        <label>Expiry Date</label>
        <input [class.is-invalid]="dd.touched&&dd.invalid" name="dd" #dd="ngModel"
               [(ngModel)]="addForm.features.disablingDate"
               required type="date"
               class="form-control" placeholder="Enter simcard number">
        <small *ngIf="dd.touched&&dd.invalid" class="alert-danger p-1">Enter a valid daate</small>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="submitAddForm()" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-plus-circle"></i> Add
    </button>

  </div>
</ng-template>

<ng-template #editItem let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title1">Edit {{edit.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form novalidate #form="ngForm">
      <div class="custom-control custom-switch mb-2">
        <input id="customSwitch2" type="checkbox" class="custom-control-input" name="isActive"
               [(ngModel)]="edit.features.canBeDisabled">
        <label class="custom-control-label" for="customSwitch2">Disabled?</label>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="edit.name"
               required type="text"
               class="form-control" placeholder="Enter user name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div *ngIf="!edit.features.canBeDisabled" class="form-group">
        <label>Expiry Date</label>
        <input [class.is-invalid]="dd.touched&&dd.invalid" name="dd" #dd="ngModel"
               [(ngModel)]="edit.features.disablingDate"
               required type="date"
               class="form-control" placeholder="Enter simcard number">
        <small *ngIf="dd.touched&&dd.invalid" class="alert-danger p-1">Enter a valid daate</small>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="updateForm()" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-upload"></i>Update
    </button>

  </div>
</ng-template>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h5 class="card-title mt-0 mb-0 header-title">Sub-Accounts</h5>
        <div class="float-sm-right mt-3 mt-sm-0">
          <div class="task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
            <form>
              <div class="input-group">
                <input name="search" [(ngModel)]="search" type="text" class="form-control search-input"
                       placeholder="Search..."/>
                <span class="uil uil-search icon-search"></span>
                <div class="input-group-append">
                  <button (click)="ngbModal.open(content)" class="btn btn-soft-primary" type="button">
                    <i class='uil uil-plus-circle'></i> Add New
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table class="table table-hover table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Created On</th>
          <th scope="col">Disabling On</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr (contextmenu)="rightClick()" *ngFor="let item of pagedobjects;let i=index;">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.email}}</td>
          <td>{{httpService.parseDate(item.createdOn)}}</td>
          <td>{{httpService.parseDate(item.features.disablingDate)}}</td>
          <td>{{item.features.canBeDisabled ? 'Disabled' : 'Enabled'}}</td>
          <td><a (click)="editObject(item);ngbModal.open(editItem)"><i class="fa fa-edit"></i></a></td>
        </tr>
        </tbody>
      </table>
    </div>
    <br>
    <div>
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
      </ngb-pagination>
    </div>

  </div>
</div>
<button (click)="ngbModal.open(content)" title="add a route" class="btn-secondary btn btn-rounded"
        style="z-index:123;margin: 10px;position: fixed;bottom: 0;right: 0;"><i
  class="fa fa-plus-circle"></i></button>
