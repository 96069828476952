<div>
  <div style="padding: 10px;" class="card">
    <form (ngSubmit)="checkHistory()" style="display: flex;align-items: center;" novalidate #form="ngForm">
      <div class="form-group">
        <label>Vehicle</label>
        <select
          [class.is-invalid]="object.touched&&object.invalid"
          #object="ngModel"
          [(ngModel)]="formHistory.imei" required name="vehicleType1" class="form-control">
          <option [value]="item.imei" *ngFor="let item of this.objects">{{item.name}}</option>
        </select>
        <small *ngIf="object.touched&&object.invalid" class="alert-danger p-1">Enter a valid object</small>
      </div>
      &nbsp;&nbsp;
      <div *ngIf="from.pristine&&to.pristine" class="form-group">
        <label>Filter</label>
        <select
          [(ngModel)]="filter"
          (change)="change()" required name="vehicleFilter" class="form-control">
          <option value="0">Last Hour</option>
          <option value="1">Today</option>
          <option value="2">Yesterday</option>
          <option value="3">Before 2 days</option>
          <option value="4">Before 3 days</option>
          <option value="5">This week</option>
        </select>
      </div>
      &nbsp;&nbsp;
      <div class="form-group">
        <label>From Date</label>
        <input #from="ngModel" [(ngModel)]="formHistory.from" name="from-date" required="required" class="form-control"
               type="datetime-local"/>
      </div>
      &nbsp;&nbsp;
      <div class="form-group">
        <label>To Date</label>
        <input #to="ngModel" [(ngModel)]="formHistory.to" name="to-date" required="required" class="form-control"
               type="datetime-local"/>
      </div>
      &nbsp;&nbsp;
      <button style="margin-top: 10px;" [disabled]="form.form.invalid||form.form.pristine"
              class="btn btn-primary"> View History
      </button>


    </form>

  </div>
  <div *ngIf="showPlayer" class="card"
       style="padding: 10px;position:absolute;bottom:-25px;z-index: 9999;width: 100%;">
    <div style="display: flex;font-weight: bold;">
      <b style="flex: 1;">Vehicle History Playback</b>
    </div>
    <hr style="margin: 0;padding: 10px;"/>
    <b>Playback Speed:
      <select [value]="playbackSpeed">
        <option value="1000">1x</option>
        <option value="500">4x</option>
        <option value="200">5x</option>
        <option value="100">6x</option>
      </select>
    </b>
    <br/>
<!--    <div style="display: block">-->
<!--      <canvas baseChart-->
<!--              width="40px" height="90px"-->
<!--              [datasets]="lineChartData"-->
<!--              [options]="lineChartOptions"-->
<!--              [colors]="lineChartColors"-->
<!--              [legend]="true"-->
<!--              chartType="line"-->
<!--              [labels]="lineChartLabels">-->
<!--      </canvas>-->
<!--    </div>-->
    <div style="display: flex;">
      <button (click)="play()" class="btn btn-primary">Play</button>
      &nbsp;&nbsp;
      <button (click)="stopPlayer()" class="btn btn-secondary">Stop</button>
      &nbsp;&nbsp;
      <button (click)="saveAsRoute()" class="btn btn-primary">Export as Route</button>
      &nbsp;&nbsp;
      <button (click)="exportAsCSV()" class="btn btn-secondary">Export as CSV</button>
      &nbsp;&nbsp;
      <button (click)="exportAsXlXS()" class="btn btn-primary">Export as Excel</button>
    </div>
  </div>

  <agm-map style="height: 68vh;" [streetViewControl]="false" [mapTypeControl]="false" [latitude]="latitude"
           [longitude]="longitude"
           [zoom]="zoom">
    <ng-container *ngIf="playerPosition">
      <agm-overlay [latitude]="playerPosition.latitude" [longitude]="playerPosition.longitude">
        <img
          [style]="'height:30px;transform:rotate('+(270+Number(playerPosition.course))+'deg);'"
          [src]="parseImage(formHistory.imei)"
          alt="marker">
      </agm-overlay>
      <agm-overlay [latitude]="playerPosition.latitude" [longitude]="playerPosition.longitude">
        <div class="card" style="background: #fff;margin: 15px;padding: 10px;">
          <b>{{this.httpService.parseDate(playerPosition.serverTime)}}</b>
        </div>
      </agm-overlay>
      <agm-polyline>
        <agm-polyline-point *ngFor="let point of lines" [latitude]="toNum(point.latitude)"
                            [longitude]="toNum(point.longitude)">
          <agm-overlay *ngIf="point.parked" [latitude]="point.latitude" [longitude]="point.longitude">
            <img style="width: 20px;height: 20px;" src="https://www.anytrack.live/img/markers/route-stop.svg"/>
          </agm-overlay>
        </agm-polyline-point>
      </agm-polyline>
    </ng-container>

  </agm-map>
</div>

