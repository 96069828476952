import {Component, OnInit, ViewChild} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Track} from '../live-tracking/live-tracking.component';

@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.css']
})
export class ObjectsComponent implements OnInit {


  constructor(public httpService: DashrequestService, public ngbModal: NgbModal) {
  }


  get pagedobjects() {
    this.collectionSize = this.objects.length;
    const search = this.search.toLowerCase();
    return this.objects
      .filter(s => s.name.toLowerCase().startsWith(search) || s.imei.toLowerCase().startsWith(search))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  objects: Track[];
  addForm = {
    imei: '',
    fuelType: 0,
    isActive: true,
    name: '',
    simNo: '',
    disablingDate: '2029-12-12',
    vehicleType: 0,
    attributes: []
  };
  savedDisabledDate = '2029-12-12';
  page = 1;
  edit: any;
  pageSize = 6;
  collectionSize = 0;
  search = '';
  @ViewChild('content') content;
  disablingDate;

  ngOnInit(): void {
    this.httpService.objects.subscribe(data => {
      this.objects = data;
    });
  }

  submitAddForm() {
    this.addForm.attributes = this.httpService.mergeKeys(this.addForm.attributes);
    this.savedDisabledDate = this.addForm.disablingDate;
    this.addForm.disablingDate = this.httpService.giveTimeStamp(this.addForm.disablingDate);
    this.httpService
      .addObject(this.addForm)
      .subscribe(
        data => {
          this.addForm.imei = this.addForm.name = '';
          this.httpService.updateData(data);
          this.ngbModal.dismissAll();
          this.addForm.disablingDate = this.savedDisabledDate;
        },
        err => {
          console.log(err);
          this.addForm.disablingDate = this.savedDisabledDate;


        }
      );
  }


  rightClick() {
    console.log(this.addForm.disablingDate);

    console.log('CLICKED');
    return false;
  }

  Date(serverTime: string) {
    return this.httpService.parseDate(serverTime);
  }

  isActive(serverTime: string) {
    return 'active';
  }

  editObject(item: Track) {
    const doIt = new Date(item.disablingDate);
    this.edit = Object.create(item);
    this.edit.attributes = this.httpService.fetchKeys(this.edit.attributes);
    this.disablingDate = doIt.toISOString().split('T')[0];

  }

  updateForm() {
    this.edit.attributes = this.httpService.mergeKeys(this.edit.attributes);
    this.ngbModal.dismissAll();
    this.edit.disablingDate = new Date(this.disablingDate).getTime();
    delete this.edit.group;
    this.httpService.updateObject(this.edit.id, this.edit)
      .subscribe(data => {
        this.httpService.updateData(data);
      }, err => console.log(err));
  }

  deleteNow(item: Track) {
    this.edit = item;
  }

  deleteObject() {
    this.ngbModal.dismissAll();
    this.httpService.deleteObject(this.edit.id)
      .subscribe(data => {
        this.httpService.removeObject(this.edit);
      }, err => console.log(err));
  }

  deleteOtherInfo(s = 'w') {
    if (s === 'E') {
      this.edit.attributes = this.edit.attributes.filter(s1 => s1[0] !== 'wisd_x');
      return;
    }
    this.addForm.attributes = this.addForm.attributes.filter(s1 => s1[0] !== 'wisd_x');

  }

  addOtherInfo(s = 'W') {
    if (s === 'E') {
      this.edit.attributes.push(['', '']);
      return;
    }
    this.addForm.attributes.push(['', '']);
  }


  Round(number: number) {
    return number.toFixed(3);
  }
}

