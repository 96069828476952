<ng-template #add let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Add a Event</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h1 *ngIf="loading">Loading</h1>
    <form novalidate #form="ngForm">
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="addForm.name"
               required type="text"
               class="form-control" placeholder="Enter event name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Objects</label>
        <ng-select
          [items]="objects"
          [multiple]="true"
          [closeOnSelect]="true"
          [searchable]="true"
          bindLabel="name"
          name="objects"
          placeholder="Select Objects"
          [(ngModel)]="selectedObjects">
        </ng-select>
      </div>
      <div class="form-group">
        <label>Type</label>
        <select [class.is-invalid]="type.touched&&type.invalid" name="type" #type="ngModel"
                [(ngModel)]="addForm.type"
                required
                class="form-control">
          <option *ngFor="let item of options;let i=index;" value="{{i}}">{{item}}</option>
        </select>
        <small *ngIf="type.touched&&type.invalid" class="alert-danger p-1">Enter a valid type</small>
      </div>
      <div class="form-group" required *ngIf="[15,16,17].includes(Number(addForm.type))">
        <label>Time Period (min)</label>
        <input class="form-control" type="number" name="timePeriod" #timePeriod="ngModel"
               [(ngModel)]="addForm.values.tPeriod">
        <small *ngIf="timePeriod.touched&&timePeriod.invalid" class="alert-danger p-1">Enter a valid timePeriod</small>
      </div>
      <div class="form-group" required *ngIf="[18,19].includes(Number(addForm.type))">
        <label>Speed limit (kph)</label>
        <input class="form-control" type="number" name="speedLimit" #speedLimit="ngModel"
               [(ngModel)]="addForm.values.speedLimit">
        <small *ngIf="speedLimit.touched&&speedLimit.invalid" class="alert-danger p-1">Enter a valid speedLimit</small>
      </div>
      <div class="form-group" required *ngIf="[27,28].includes(Number(addForm.type))">
        <label>Routes</label>
        <select [(ngModel)]="addForm.values.routeId" name="routes" class="form-control">
          <option *ngFor="let item of routes" value="{{item.id}}">{{item.name}}</option>
        </select>
      </div>
      <div class="form-group" required
           *ngIf="[29,30].includes(Number(addForm.type))">
        <label>Zones</label>
        <select [(ngModel)]="addForm.values.zoneId" name="routes" class="form-control">
          <option *ngFor="let item of zones" value="{{item.id}}">{{item.name}}</option>
        </select>
      </div>
      <div>
        <div class="form-group" *ngIf="addForm.type==24">
          <label>Sensors</label>
          <br>
          <div style="margin-top: 2px;" class="row"
               *ngFor="let item of addForm.values.parametersAndSensors;let i=index;">
            <div class="col-sm-5">
              <input name="attrib_{{i}}"
                     [(ngModel)]="item.name"
                     required
                     type="text"
                     class="form-control" placeholder="Enter field">
            </div>
            <div class="col-sm-6">
              <input
                [(ngModel)]="item.value"
                required type="text"
                name="value_{{i}}"
                #v_{{i}}
                class="form-control" placeholder="Enter value">
            </div>
            <button (click)="item.name='wisd_x';deleteOtherInfo()" class="btn btn-danger"><i class="fa fa-trash"></i>
            </button>
          </div>
          <br>
          <button type="button" (click)="addOtherInfo()" class="btn btn-primary">Add <i class="fa fa-plus-circle"></i>
          </button>
        </div>

      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="submitAddForm()"
            [disabled]="selectedObjects.length<1||form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-plus-circle"></i> Add
    </button>

  </div>
</ng-template>
<ng-template #editForm let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Add a Event</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h1 *ngIf="loading">Loading</h1>
    <form novalidate #form="ngForm">
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="edit.name"
               required type="text"
               class="form-control" placeholder="Enter event name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Vehicles</label>
        <ng-select
          [items]="objects"
          [multiple]="true"
          [closeOnSelect]="true"
          [searchable]="true"
          bindLabel="name"
          name="objects"
          placeholder="Select Objects"
          [(ngModel)]="selectedObjects">
        </ng-select>
      </div>
      <div class="form-group">
        <label>Type</label>
        <select [class.is-invalid]="type.touched&&type.invalid" name="type" #type="ngModel"
                [(ngModel)]="edit.type"
                required
                class="form-control">
          <option *ngFor="let item of options;let i=index;" value="{{i}}">{{item}}</option>
        </select>
        <small *ngIf="type.touched&&type.invalid" class="alert-danger p-1">Enter a valid type</small>
      </div>
      <div class="form-group" *ngIf="[15,16,17].includes(Number(edit.type))">
        <label>Time Period (min)</label>
        <input class="form-control" type="number" required name="timePeriod" #timePeriod="ngModel"
               [(ngModel)]="edit.values.tPeriod">
        <small *ngIf="timePeriod.touched&&timePeriod.invalid" class="alert-danger p-1">Enter a valid timePeriod</small>

      </div>
      <div class="form-group" *ngIf="[18,19].includes(Number(edit.type))">
        <label>Speed limit (kph)</label>
        <input required class="form-control" type="number" name="speedLimit" #speedLimit="ngModel"
               [(ngModel)]="edit.values.speedLimit">
        <small *ngIf="speedLimit.touched&&speedLimit.invalid" class="alert-danger p-1">Enter a valid speedLimit</small>
      </div>
      <div class="form-group" required *ngIf="[27,28].includes(Number(edit.type))">
        <label>Routes</label>
        <select name="routes" class="form-control">
          <option *ngFor="let item of routes" value="{{item.id}}">{{item.name}}</option>
        </select>
      </div>
      <div class="form-group" required *ngIf="[29,30].includes(Number(edit.type))">
        <label>Zones</label>
        <select name="routes" class="form-control">
          <option *ngFor="let item of zones" value="{{item.id}}">{{item.name}}</option>
        </select>
      </div>

      <div>
        <div class="form-group" *ngIf="edit.type==24">
          <label>Sensors</label>
          <br>
          <div style="margin-top: 2px;" class="row"
               *ngFor="let item of edit.values.parametersAndSensors;let i=index;">
            <div class="col-sm-5">
              <input name="attrib_{{i}}"
                     [(ngModel)]="item.name"
                     required
                     type="text"
                     class="form-control" placeholder="Enter field">
            </div>
            <div class="col-sm-6">
              <input
                [(ngModel)]="item.value"
                required type="text"
                name="value_{{i}}"
                #v_{{i}}
                class="form-control" placeholder="Enter value">
            </div>
            <button (click)="item.name='wisd_x';deleteOtherInfo(1)" class="btn btn-danger"><i class="fa fa-trash"></i>
            </button>
          </div>
          <br>
          <button type="button" (click)="addOtherInfo(1)" class="btn btn-primary">Add <i class="fa fa-plus-circle"></i>
          </button>
        </div>

      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="updateForm()" [disabled]="selectedObjects.length<1||form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-cloud-upload-alt"></i> Update
    </button>

  </div>
</ng-template>
<ng-template #delete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title3">Are you sure?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Deleted Objects cannot be undone !</h3>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="deleteObject()">Yes</button>
  </div>
</ng-template>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h5 class="card-title mt-0 mb-0 header-title">Events</h5>
        <div class="float-sm-right mt-3 mt-sm-0">
          <div class="task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
            <form>
              <div class="input-group">
                <input name="search" [(ngModel)]="search" type="text" class="form-control search-input"
                       placeholder="Search..."/>
                <span class="uil uil-search icon-search"></span>
                <div class="input-group-append">
                  <button (click)="showAdd();ngbModal.open(add)" class="btn btn-soft-primary" type="button">
                    <i class='uil uil-plus-circle'></i> Add New
                  </button>
                  <button (click)="this.httpService.exportTable('data-table')"
                          class="btn btn-soft-secondary"><i class="fa fa-file-export"></i> Export as Excel Sheet
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table id="data-table" class="table table-hover table-nowrap mb-0">
        <thead>
        <tr>
          <th>S.N</th>
          <th scope="col">Name</th>
          <th scope="col">Objects</th>
          <th scope="col">Type</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr (contextmenu)="rightClick()" *ngFor="let item of pagedobjects;let i=index;">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>
            <span *ngFor="let o of item.objects"><span class="badge badge-soft-primary">{{Parse(o)}}</span>&nbsp;
            </span>
          </td>
          <td>{{options[item.type]}}</td>
          <td><a (click)="editObject(item);ngbModal.open(editForm)"><i class="fa fa-edit"></i></a>
            &nbsp;
            <a
              (click)="deleteNow(item);ngbModal.open(delete)">&nbsp;<i
              class="fa fa-trash"></i></a></td>
        </tr>
        </tbody>
        <div *ngIf="pagedobjects.length===0" style="padding: 10px;">
          No events found.
        </div>

      </table>
    </div>
    <br>
    <div>
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
      </ngb-pagination>
    </div>

  </div> <!-- end card-body-->
</div> <!-- end card-->
<button (click)="ngbModal.open(add)" title="add a route" class="btn-secondary btn btn-rounded"
        style="z-index:123;margin: 10px;position: fixed;bottom: 0;right: 0;"><i
  class="fa fa-plus-circle"></i></button>
