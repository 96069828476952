import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, ObservableInput, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LoadingService} from '../services/loading.service';
import {Router} from '@angular/router';
import {Const} from '../Const';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {ToastService} from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(public loadingService: LoadingService, private router: Router,
              @Inject(LOCAL_STORAGE) private storage: StorageService, private toastService: ToastService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const uToken = this.storage.get('uToken');
    if (uToken != null) {
      req = req.clone({headers: req.headers.set('Authorization', `Bearer ${uToken.token}`)});
    }
    this.loadingService.isLoading.next(true);
    return new Observable(o => {
      next.handle(req).subscribe(
        event => {
          o.next(event);
        }, err => {
          this.loadingService.isLoading.next(false);
          if (req.url.split('/').pop() !== 'login' && req.url.split('/').pop() !== 'register') {
            this.handleError(err, o);
          } else {
            o.error(err);
          }
        }, () => {
          this.loadingService.isLoading.next(false);
          o.complete();
        }
      );
    });
  }

  handleError(err, o) {
    if (err.status === 406) {
      this.toastService.show(err.error.reason);
    }
    if (err.status === 401) {
      this.storage.set(Const.ERROR_MSG, 'Please login first.');
      this.router.navigate(['login']).then();
      return;
    }
    o.error(err);
  }
}
