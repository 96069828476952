<div style="display: flex;flex-direction: column;">
  <agm-map  [streetViewControl]="false" [mapTypeControl]="false" [latitude]="latitude"
           [longitude]="longitude"
           [zoom]="zoom">

    <agm-overlay [latitude]="mark.lastPosition.latLng.split(':')[0]"
                 [longitude]="mark.lastPosition.latLng.split(':')[1]" *ngFor="let mark of pagedobjects">

      <img *ngIf="mark.lastPosition.latLng!='0:0'" (click)="onRightClick(mark)"
           [style]="'height:30px;transform:rotate('+(270+toNum(mark.lastPosition.angle))+'deg);'"
           [src]="parseImages(mark)" alt="marker">
    </agm-overlay>

    <ng-container *ngFor="let mark of pagedobjects">
      <agm-polyline-point *ngFor="let point of mark.lastLocations" [latitude]="toNum(point.lat)"
                          [longitude]="toNum(point.lon)">
      </agm-polyline-point>
    </ng-container>
    <agm-polygon fillColor="#00ff001C" *ngFor="let item of routes" [paths]="item.points">
    </agm-polygon>
    <agm-circle *ngFor="let item of zones" [fillColor]="item.color" [(radius)]="item.radius" [latitude]="item.lat"
                [longitude]="item.lng">
    </agm-circle>
    <ng-container *ngIf="lines.length>0&&(select===3||select===2)">
      <agm-overlay *ngFor="let point of lines" [latitude]="toNum(point.latitude)"
                   [longitude]="toNum(point.longitude)">
        <img [style]="'transform:rotate('+point.course+'deg);'" src="/assets/images/history.svg" alt="marker">
      </agm-overlay>
      <agm-polyline>
        <agm-polyline-point *ngFor="let point of lines" [latitude]="toNum(point.latitude)"
                            [longitude]="toNum(point.longitude)">
        </agm-polyline-point>
      </agm-polyline>
    </ng-container>
  </agm-map>
  <div class="card" style="flex: 1;">
    <table class="table table-hover table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">SN</th>
        <th scope="col">Name</th>
        <th scope="col">IMEI</th>
        <th scope="col">SimCard No</th>
        <th scope="col">Status</th>
        <th scope="col">Last Connection</th>
        <th scope="col">Expires On</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>

</div>
