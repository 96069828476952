<ng-template #changefuel let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Change Fuel</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Disel Price</label>
      <input [class.is-invalid]="price.touched&&price.invalid" name="price" #price="ngModel" [(ngModel)]="newCost.disel"
        required type="number" class="form-control" placeholder="Enter new price">
      <small *ngIf="price.touched&&price.invalid" class="alert-danger p-1">Enter a valid price</small>
    </div>
    <div class="form-group">
      <label>Petrol Price</label>
      <input [class.is-invalid]="petrol.touched&&petrol.invalid" name="price" #petrol="ngModel"
        [(ngModel)]="newCost.petrol" required type="number" class="form-control" placeholder="Enter new price">
      <small *ngIf="petrol.touched&&petrol.invalid" class="alert-danger p-1">Enter a valid price</small>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="changeCost()" [disabled]="petrol.invalid|| price.invalid" class="btn btn-outline-secondary"><i
        class="fa fa-upload"></i> Update
    </button>

  </div>
</ng-template>
<div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
  <div class="container-fluid">
    <!-- LOGO -->
    <a [routerLinkActive]="'active'" [routerLink]="['/dash','live']" class="navbar-brand mr-0 mr-md-2 logo">
      <span class="logo-lg">
        <img src="/assets/images/sawari.png" alt="" height="64" />
      </span>
      <span class="logo-sm">
        <img src="/assets/images/sawari.png" alt="" height="24">
      </span>
    </a>

    <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
      <li class="">
        <button (click)="toggle()" class="button-menu-mobile open-left disable-btn">
          <i class="fa fa-bars"></i>
        </button>
      </li>
    </ul>


    <ul class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
      <li class="d-none d-sm-block">
        <div class="app-search">
          <span title="Online Vehicles" class="badge badge-soft-primary">
            <img style="width: 20px;height: 20px;" src="https://www.anytrack.live/theme/images/total_objects.svg" />
            {{data.online}}
          </span>
          <span title="Moving Vehicles" class="badge badge-soft-primary" style="margin-left: 1px;">
            <img style="width: 20px;height: 20px;"
              src="https://www.anytrack.live/theme/images/connection-gsm-gps.svg" />
            {{data.moving}}
          </span>
          <span title="Stopped Vehicles" class="badge badge-soft-primary" style="margin-left: 1px;">
            <img style="width: 20px;height: 20px;"
              src="https://www.anytrack.live/theme/images/connection-gsm-red.svg" />
            {{data.standby}}
          </span>
          <span title="Inactive objects" class="badge badge-soft-primary" style="margin-left: 1px;">
            <img style="width: 20px;height: 20px;" src="https://www.anytrack.live/theme/images/connection-no.svg" />
            {{data.inactive}}
          </span>

        </div>
      </li>
      <li class="d-none d-sm-block">
        <div class="app-search">
          <span (click)="ngbModal.open(changefuel)" title="click to change"
            class="show-pointer badge badge-soft-primary p-2">Diesel : रू{{newCost.disel}}
            Petrol: रू{{newCost.petrol}}</span>
        </div>
      </li>


      <li class="dropdown notification-list" data-toggle="tooltip" data-placement="left"
        title="8 new unread notifications">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="false"
          aria-expanded="false">
          <i class="fa fa-bell"></i>
          <span *ngIf="this.unseenNotifications>0" class="noti-icon-badge"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-lg">

          <!-- item-->
          <div class="dropdown-item noti-title border-bottom">
            <h5 class="m-0 font-size-16">
              <span class="float-right">
                <button (click)="markAsRead()" class="text-dark">
                  <small>Mark as read</small>
                </button>
              </span>Notifications
            </h5>
          </div>

          <div class="slimscroll noti-scroll">
            <div *ngFor="let noti of notifications" class="dropdown-item notify-item border-bottom">
              <div class="notify-icon bg-primary"><i class="fa fa-bell"></i></div>
              <p class="notify-details">{{noti.text}}<small class="text-muted">{{httpService.parseDate(noti.time)}}</small>
              </p>
            </div>


          </div>
        </div>
      </li>
      <li class="dropdown notification-list" data-toggle="tooltip" data-placement="left">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="false"
          aria-expanded="false">
          <i class="fa fa-user-circle"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-lg">

          <!-- item-->
          <div class="dropdown-item noti-title border-bottom">
            <h5 class="m-0 font-size-16">
              <span class="float-right">
              </span>Options
            </h5>
          </div>

          <div class="slimscroll noti-scroll">
            <a class="dropdown-item notify-item border-bottom" [routerLinkActive]="'active'"
              [routerLink]="['/dash','sub-accounts']">
              <div class="notify-icon bg-primary"><i class="uil uil-wrench"></i></div>
              <p class="notify-details">Sub Accounts</p>
            </a>

          </div>
          <div class="slimscroll noti-scroll">
            <a (click)="logout()" class="dropdown-item notify-item border-bottom">
              <div class="notify-icon bg-primary"><i class="uil uil-sign-out-alt"></i></div>
              <p class="notify-details">Logout</p>
            </a>

          </div>

        </div>
      </li>
    </ul>
  </div>

</div>
<div style="z-index: 999!important;" class="left-side-menu" [class.left-bar]="shown">
  <div class="media user-profile mt-2 mb-2">
    <img src="/assets/images/default.png" class="avatar-sm rounded-circle mr-2" />

    <div class="media-body">
      <h6 class="pro-user-name mt-0 mb-0">{{userData.name}}</h6>
      <span class="pro-user-desc">{{parseRole(userData.role)}}</span>
    </div>
  </div>
  <div class="sidebar-content">
    <!--- Sidemenu -->
    <div id="sidebar-menu" class="slimscroll-menu" style="min-height: 512px;">
      <ul  (click)="shown=false;" class="metismenu" id="menu-bar">
        <li class="menu-title">Monitoring</li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','live']">
            <i class="fa fa-tv"></i>
            <span> Live Tracking </span>

          </a>
        </li>
        <li class="menu-title">Navigation</li>

        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','home']">
            <i class="fa fa-home"></i>
            <!--            <span class="badge badge-success float-right">1</span>-->
            <span> Home </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" id="step-1" [routerLink]="['/dash','objects']">
            <i class="fa fa-car"></i>
            <span> Vehicles </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','history']">
            <i class="fa fa-history"></i>
            <span> History </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','reports']">
            <i class="fa fa-book"></i>
            <span> Reports </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','drivers']">
            <i class="fa fa-male"></i>
            <span> Drivers </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','events']">
            <i class="fa fa-bell"></i>
            <span> Events </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','routes']">
            <i class="fa fa-route"></i>
            <span> Routes </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','zones']">
            <i class="uil uil-globe"></i>
            <span> Zones </span>
          </a>
        </li>
        <li>
          <a [routerLinkActive]="'active'" [routerLink]="['/dash','config']">
            <i class="uil uil-wrench"></i>
            <span> Configurations </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
