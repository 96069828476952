import {Component, Inject, OnInit} from '@angular/core';
import {DashrequestService, Positions} from "../services/dashrequest.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DashboardComponent} from "../dashboard/dashboard.component";
import {DefaultsService} from "../services/defaults.service";
import {Track, TYPES} from "../live-tracking/live-tracking.component";

@Component({
  selector: 'app-new-tracking',
  templateUrl: './new-tracking.component.html',
  styleUrls: ['./new-tracking.component.css']
})
export class NewTrackingComponent implements OnInit {
  latitude = 39.8282;
  longitude = -98.5795;
  clicked = null;
  page = 1;
  formHistory = {
    imei: '',
    from: new Date(),
    to: new Date()
  };

  pageSize = 6;
  routes: any = [];
  zones: any = [];
  collectionSize = 0;
  select = 0;
  zoom = 16;
  objects: Track[];
  lines: Positions[] = [];
  alerts = [];

  constructor(public httpService: DashrequestService, private modalService: NgbModal,
              @Inject(DashboardComponent) private parent: DashboardComponent, private defaultsService: DefaultsService) {

  }
  ngOnInit(): void {
    this.httpService.getAlerts()
      .subscribe(data => {
        this.alerts = data;
        this.httpService.getZones().subscribe(
          zones => {
            this.zones = zones.map(s => {
              const x1 = s.points.split(':');
              s.lat = Number(x1[0]);
              s.lng = Number(x1[1]);
              return s;
            });
          });

        this.httpService.getRoutes().subscribe(
          // tslint:disable-next-line:no-shadowed-variable
          data => {
            if (this.objects.length > 0) {
              this.formHistory.imei = this.objects[0].imei;
            }
            this.routes = data;
            this.routes = this.routes.map(s => {
              s.points = s.points.split(',');
              s.points = s.points.map(w => {
                const p = w.split(':');
                return {lat: Number(p[0]), lng: Number(p[1])};
              });
              return s;
            });
          }, error => console.log(error));
      });
    const x = this.defaultsService.getLocation();
    this.latitude = Number(x[0]);
    this.longitude = Number(x[1]);
    this.zoom = this.defaultsService.getData('defaultZoom');
    this.httpService.objects.subscribe(data => {
      this.objects = data;
    });

  }

  get pagedobjects() {
    this.collectionSize = this.objects.length;
    return this.objects.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }


  toNum(lat: any) {
    return Number(lat);
  }
  parseImages(mark: Track) {
    return '/assets/map-icons/' + TYPES[mark.vehicleType] + '.png';
  }


  onRightClick(mark: Track) {

  }
}
