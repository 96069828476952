import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class DefaultsService {

  data = {
    defaultLocation: '27.669995666666665:85.3204645',
    defaultZoom: 16,
    defaultOnlineTime: 60,
    defaultExpiryDate: '2029-04-06'
  };

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {

  }

  getData(str: any) {
    return (this.storage.has(str) ? this.storage.get(str) : this.data[str]);
  }

  setData(str: string, data: any) {
    this.storage.set(str, data);
  }

  getLocation() {
    return this.getData('defaultLocation').split(':');
  }
}
