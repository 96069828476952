import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DashrequestService, Positions} from '../services/dashrequest.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {DefaultsService} from '../services/defaults.service';
import {Router} from "@angular/router";
import {ToastService} from "../services/toast.service";
import {split} from "ts-node";

@Component({
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{item.name}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <li class="table-striped">IMEI : {{item.imei}}</li>
      <li class="table-striped">Last Online : {{Date(item.lastPosition.serverTime)}}</li>
      <li class="table-striped">Speed : {{item.lastPosition.speed}} kph</li>
      <li class="table-striped">Angle : {{item.lastPosition.angle}}</li>
      <!--      <li class="table-striped">Attributes : {{item.lastPosition.attributes}}</li>-->
    </div>
    <div class="modal-footer" style="font-size: 14px;">
      <div class="btn btn-outline-info" (click)="change('E',item)">Edit Object</div>
      <div class="btn btn-outline-secondary" (click)="change('H',item)">View History</div>
      <div class="btn btn-outline-danger" (click)="change('D',item)">Delete Object</div>
    </div>
  `
})
export class ModelComponent {
  public change;
  public item: Track;

  constructor(public activeModal: NgbActiveModal) {
  }

  Date(serverTime: string) {
    return serverTime === '0' ? 'No Data' : new Date(Number(serverTime));
  }


  parse(lastLocations: LastPosition[]) {
    console.log(lastLocations);
    return JSON.stringify(lastLocations);
  }
}

export interface LastPosition {
  lat: string;
  lon: string;
}

export interface Track {
  lastPosition: {
    latLng: string,
    angle: string,
    serverTime: string,
    speed: number,
    attributes: string
  };
  simNo: string;
  id: string;
  isActive: boolean;
  disablingDate: number;
  attributes: Attribute;
  imei: string;
  vehicleType: number;
  name: string;
  group: string;
  lastLocations: LastPosition[];
}

export interface Attribute {
  ignition: boolean;
  status: number;
  io1: string;
  io2: string;
  io3: string;
  io4: string;
  distance: number;
  totalDistance: number;
  motion: boolean;
  batteryLevel: number;
}

export const TYPES = ['bus', 'bus', 'bike', 'bus'];

@Component({
  selector: 'app-live-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.css']
})

export class LiveTrackingComponent implements OnInit {

  constructor(public httpService: DashrequestService, private modalService: NgbModal,
              private toastService: ToastService,
              @Inject(DashboardComponent) private parent: DashboardComponent, private defaultsService: DefaultsService,
              public router: Router) {

  }

  get pagedobjects() {
    this.collectionSize = this.objects.length;
    return this.objects.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  objects: Track[];
  showInfo = false;
  addForm = {
    imei: '',
    name: '',
    simNo: '',
    vehicleType: 0
  };
  /*Declarations*/
  @ViewChild('name') name;
  @ViewChild('content') content;
  latitude = 39.8282;
  longitude = -98.5795;
  clicked: Track = null;
  page = 1;
  pageSize = 6;
  routes: any = [];
  zones: any = [];
  collectionSize = 0;
  select = 0;
  zoom = 16;
  edit: Track;
  historyTime = 0;
  options = ['SOS', 'Bracelet On', 'Bracelet Off', 'Man Down', 'Shock', 'Tow', 'Power cut', 'Gps antenna cut', 'Signal jamming',
    'Low DC', 'Low Battery', 'Connection:Yes', 'Connection:No', 'Gps:Yes', 'Gps:No', 'Stopped', 'Moving', 'Engine Idle', 'Over ' +
    'Speed', 'Under Speed', 'Harsh Acceleration', 'Harsh Breaking', 'Harsh Cornering', 'Parameter', 'Sensor', 'Service', 'DTC' +
    ' (Diagnostic Trouble Codes)', 'Route In', 'Route Out', 'Zone In', 'Zone Out'];
  lines: Positions[] = [];
  alerts = [];
  formHistory = {
    imei: '',
    from: new Date(),
    to: new Date()
  };

  ngOnInit(): void {
    this.httpService.getAlerts()
      .subscribe(data => {
        this.alerts = data;
        this.httpService.getZones().subscribe(
          zones => {
            this.zones = zones.map(s => {
              const x1 = s.points.split(':');
              s.lat = Number(x1[0]);
              s.lng = Number(x1[1]);
              return s;
            });
          });

        this.httpService.getRoutes().subscribe(
          // tslint:disable-next-line:no-shadowed-variable
          data => {
            if (this.objects.length > 0) {
              this.formHistory.imei = this.objects[0].imei;
            }
            this.routes = data;
            this.routes = this.routes.map(s => {
              s.points = s.points.split(',');
              s.points = s.points.map(w => {
                const p = w.split(':');
                return {lat: Number(p[0]), lng: Number(p[1])};
              });
              return s;
            });
          }, error => console.log(error));
      });
    const x = this.defaultsService.getLocation();
    this.latitude = Number(x[0]);
    this.longitude = Number(x[1]);
    this.zoom = this.defaultsService.getData('defaultZoom');
    this.httpService.objects.subscribe(data => {
      this.objects = data;
    });

  }

  changeClick(item) {
    this.showInfo = true
    this.clicked = item;
    const latlon = item.lastPosition.latLng.split(':');
    this.latitude = Number(latlon[0]);
    this.longitude = Number(latlon[1]);
  }


  submitAddForm() {
    this.httpService
      .addObject(this.addForm)
      .subscribe(
        data => {
          this.addForm.imei = this.addForm.name = '';
          this.httpService.updateData(data);
          // this.httpService.objects.push(data);
          this.select = 0;
        },
        err => console.log(err)
      );
  }

  cancelAdd() {

  }


  Date(serverTime: string) {
    return serverTime === '0' ? 'Not connected yet.' : this.httpService.parseDate(serverTime);
  }


  click() {
    console.log('click');
  }

  onRightClick(item) {
    const x = this.modalService.open(ModelComponent, {centered: true, size: 'sm'});
    x.componentInstance.item = item;
    x.componentInstance.change = (type: string, imei: Track) => {
      x.dismiss();
      this.edit = Object.create(imei);
      if (type === 'E') {
        this.select = 4;
      } else if (type === 'H') {
        if (this.edit !== undefined && this.edit.imei !== imei.imei) {
          this.lines = [];
        }
        this.select = 3;
      } else {
        this.modalService.open(this.content);
      }
    };
    return false;
  }


  updateForm() {
    const {name, imei, vehicleType, id} = this.edit;
    const push = {name, imei, vehicleType};
    this.httpService.updateObject(id, push)
      .subscribe(data => {
        this.httpService.updateData(data);
        this.select = 0;
      }, err => console.log(err));

  }

  showPlayer = false;
  playbackSpeed = 150;
  playerPosition = null;
  playerIndex = 0;

  getTime = () => {
    const now = Date.now();
    return [[now, now - 3600000], [now, now - 86400000],
      [now - 86400000, now - 172800000], [now - 172800000, now - 259200000],
      [now, now - 604800000], [now, now - 2592000000]];
  };

  historyForm() {
    // this.httpService.objectHistory(this.edit.id, this.historyTime)
    //   .subscribe(s => {
    //     this.lines = s;
    //     if (this.lines.length > 0) {
    //       this.showPlayer = true
    //       this.zoom = 20
    //       this.playerIndex = 0
    //       this.playerPosition = this.lines[this.playerIndex]
    //       this.latitude = s[0].latitude
    //       this.longitude = s[0].longitude
    //     } else {
    //       this.toastService.show('No History found.')
    //     }
    //   }, error => console.log(error));
    const time = this.getTime()[this.historyTime];
    const to = new Date();
    const from = new Date();
    to.setTime(time[0])
    from.setTime(time[1])
    localStorage.setItem('history_time', JSON.stringify({
      id: this.edit.id,
      imei: this.edit.imei,
      to: to.toISOString().split("T")[0] + "T" + to.toTimeString().split(" ")[0],
      from: from.toISOString().split("T")[0] + "T" + from.toTimeString().split(" ")[0]
    }))
    this.router.navigate(['dash/history'])

  }

  delete(modal) {
    modal.dismiss();
    this.httpService.deleteObject(this.edit.id)
      .subscribe(data => {
        this.httpService.removeObject(this.edit);
        // this.httpService.objects = this.objects.filter(x => x.imei !== this.edit.imei);
        this.edit = null;
      }, err => console.log(err));
  }

  toNum(lat: any) {
    return Number(lat);
  }

  prettyDate(time) {
    // tslint:disable-next-line:one-variable-per-declaration
    const date = new Date(Number(time)),
      diff = (((new Date()).getTime() - date.getTime()) / 1000),
      // tslint:disable-next-line:variable-name
      day_diff = Math.floor(diff / 86400);
    console.log(date);
    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) {
      return;
    }

    // tslint:disable-next-line:triple-equals
    return day_diff == 0 && (
      diff < 60 && 'just now' || diff < 120 && '1 minute ago' || diff < 3600 && Math.floor(diff / 60) +
      ' minutes ago' || diff < 7200 && '1 hour ago' || diff < 86400 && Math.floor(diff / 3600) + ' hours ago') ||
      // tslint:disable-next-line:triple-equals
      day_diff == 1 && 'Yesterday' || day_diff < 7 && day_diff + ' days ago' || day_diff <
      31 && Math.ceil(day_diff / 7) + ' weeks ago';
  }

  parseTime(serverTime: any) {
    return !((Date.now() - Number(serverTime)) > (Number(this.defaultsService.getData('defaultOnlineTime')) * 1000));
  }

  parseImages(mark: Track) {
    return '/assets/map-icons/' + TYPES[mark.vehicleType] + '.png';
  }

  Number(angle: string) {
    return Number(angle);
  }

  checkHistory() {
    // console.log(this.formHistory.to, this.formHistory.from);
    this.httpService.singleObjectHisory(this.formHistory.imei, new Date(this.formHistory.to).getTime(),
      new Date(this.formHistory.from).getTime())
      .subscribe(s => {
        this.lines = s;
        if (this.lines.length > 0) {
          this.latitude = s[0].latitude;
          this.longitude = s[0].longitude;
        }
      }, error => console.log(error));

  }

  Round(string: any) {
    return Number(string).toFixed(4)
  }


  interval = null;

  intervalPlay() {
  }

  play() {

    if (this.interval === null) this.stopPlayer();
    this.interval = setInterval(() => {
      if (this.playerIndex < this.lines.length) {
        this.playerIndex += 1
        this.playerPosition = this.lines[this.playerIndex]
        this.latitude = this.playerPosition.latitude
        this.longitude = this.playerPosition.longitude
      } else this.stopPlayer()

    }, this.playbackSpeed)
  }

  stopPlayer() {
    clearInterval(this.interval)
  }

  onPointClick() {
    console.log('yo')
  }

  saveAsRoute() {
    localStorage.setItem("save_routes", JSON.stringify(this.lines.map(({latitude, longitude}) => ({
      lat: () => latitude,
      lon: () => longitude
    }))))
    this.router.navigate(['dash/routes'])

  }

  log(playerPosition: any) {
    return JSON.stringify(playerPosition)
  }
}
