export class Const {
  // public static BASE_URL = 'http://localhost:3000/';
 public static BASE_URL = 'https://backend.hamrosawari.com/';
  public static BASE_API_URL = Const.BASE_URL + 'iapi/';
  public static LOGIN_URL = Const.BASE_API_URL + 'users/login';
  public static REGISTER_URL = Const.BASE_API_URL + 'users/register';
  public static SUCCESS_MSG = 'smessage';
  public static ERROR_MSG = 'emessage';
  static LOAD_OBJECTS_URL = Const.BASE_API_URL + 'objects';
  public static TOKEN = 'uToken';
  static LOGOUT_URL = Const.BASE_API_URL + 'users/logout';
  static ADD_OBJECT_URL = Const.BASE_API_URL + 'objects/add';
  static UPDATE_OBJECT = Const.BASE_API_URL + 'objects/edit/';
  static HISTORY_OBJECT = Const.BASE_API_URL + 'objects/history/';
  static HISTORY_CUSTOM_OBJECT = Const.BASE_API_URL + 'objects/custom_history/';
  static DELETE_OBJECT = Const.BASE_API_URL + 'objects/delete/';
  static DRIVERS = Const.BASE_API_URL + 'drivers';
  static ADD_DRIVER = Const.DRIVERS + '/add';
  static EDIT_DRIVER = Const.DRIVERS + '/edit/';
  static EVENTS = Const.BASE_API_URL + 'events';
  static GET_NOTIFICATIONS = Const.BASE_API_URL + 'notifications';
  static ADD_EVENT = Const.EVENTS + '/add';
  static EDIT_EVENT = Const.EVENTS + '/edit/';
  static DELETE_EVENT = Const.EVENTS + '/del/';
  static ROUTES = Const.BASE_API_URL + 'routes';
  static ROUTES_ADD = Const.ROUTES + '/add';
  static REQUEST_DEMO = Const.BASE_API_URL + '/demo';
  static ROUTES_EDIT = Const.ROUTES + '/edit/';
  static DELETE_ROUTE = Const.ROUTES + '/del/';
  static ASSIGN_OBJECT = Const.LOAD_OBJECTS_URL + '/assign/';
  static ZONE = Const.BASE_API_URL + 'zones';
  static ZONE_ADD = Const.ZONE + '/add';
  static ZONE_EDIT = Const.ZONE + '/edit/';
  static ZONE_DELETE = Const.ZONE + '/del/';
  static ROLES = ['User', 'Manager', 'Admin', 'Super Admin'];
  static SUBACCOUNT = Const.BASE_API_URL + 'users/sub';
  static SUBACCOUNT_ADD = Const.SUBACCOUNT + '/add';
  static SUBACCOUNT_EDIT = Const.SUBACCOUNT + '/edit/';
  static STATS = Const.LOAD_OBJECTS_URL + '/stats';
  static ALERTS = Const.BASE_API_URL + '/objectEvents';
  static FUELCOST = Const.BASE_API_URL + '/fuel/fetch/';
  static UPDATE_FUEL = Const.BASE_API_URL + '/fuel/update';
  static REPORTS = Const.BASE_API_URL + 'reports/';
  static ADD_REPORT = Const.REPORTS + 'generate/';
  static SAVE_REPORT = Const.REPORTS + 'save/';
  static DELETE_REPORT = Const.REPORTS + 'del/';

}
