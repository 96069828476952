import {Inject, Injectable} from '@angular/core';
import {GuardService} from './guard.service';
import {Router} from '@angular/router';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Const} from '../Const';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: GuardService, private router: Router, @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.storage.set(Const.ERROR_MSG, 'Please login first.');
      this.router.navigate(['login']).then();
      return false;
    }
    return true;
  }
}
