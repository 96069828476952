import {Inject, Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Const} from '../Const';

@Injectable({
  providedIn: 'root'
})
export class SocketService extends Socket {

  // connected = false;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    super({url: Const.BASE_URL, options: {query: `token=${storage.get('uToken').token}`}});
  }

  // disconnect(close?: any): any {
  //   this.connected = false;
  //   return super.disconnect(close);
  // }

}
