import {BrowserModule} from '@angular/platform-browser';
import {NgModule, Pipe} from '@angular/core';
import {NgProgressModule} from 'ngx-progressbar';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {JwtModule} from '@auth0/angular-jwt';
import {httpInterceptorProviders} from './http-interceptors';
import {LoadingService} from './services/loading.service';
import {NavComponent} from './nav/nav.component';
import {SocketIoModule} from 'ngx-socket-io';
import {SocketService} from './services/socket.service';
import {ChartsModule} from 'ng2-charts';
import {LiveTrackingComponent} from './live-tracking/live-tracking.component';
import {DriversComponent} from './drivers/drivers.component';
import {ObjectsComponent} from './objects/objects.component';
import {EventsComponent} from './events/events.component';
import {RoutesComponent} from './routes/routes.component';
import {ZonesComponent} from './zones/zones.component';
import {SubAccountsComponent} from './sub-accounts/sub-accounts.component';
import {DashComponent} from './dash/dash.component';
import {AccountsComponent} from './accounts/accounts.component';
import {AgmOverlays} from 'agm-overlays';
import {AgmCoreModule} from '@agm/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastsContainer} from './services/toasts-container';
import {ConfigComponent} from './config/config.component';
import {FeatherModule} from 'angular-feather';
import {Bell, Shuffle, Truck, Users} from 'angular-feather/icons';
import {CircleColorPickerModule} from 'ng-color/circle-color-picker/circle-color-picker.module';
import {NgColorModule} from 'ng-color';
import {ReportsComponent} from './reports/reports.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FAQComponent} from './faq/faq.component';
import {RecaptchaModule} from 'ng-recaptcha';
import { NewTrackingComponent } from './new-tracking/new-tracking.component';
import { HistoryComponent } from './history/history.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    LiveTrackingComponent,
    DriversComponent,
    ObjectsComponent,
    EventsComponent,
    RoutesComponent,
    ZonesComponent,
    SubAccountsComponent,
    DashComponent,
    AccountsComponent,
    ToastsContainer,
    ConfigComponent,
    ReportsComponent,
    FAQComponent,
    NewTrackingComponent,
    HistoryComponent
  ],
  imports: [
    BrowserModule,
    SocketIoModule,
    NgSelectModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC8gEcZb8nMryicQePBskOmcbzhGZzuQuw',
      libraries: ['drawing']
    }),
    NgColorModule,
    FeatherModule.pick({
      Shuffle,
      Truck,
      Bell,
      Users
    }),
    RecaptchaModule,
    JwtModule.forRoot({
      config: {}
    }),
    AppRoutingModule,
    NgProgressModule.withConfig({
      color: '#e05655',
      thick: true
    }),
    ChartsModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule, HttpClientModule, FeatherModule, ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})],
  providers: [LoadingService, httpInterceptorProviders, SocketService],
  bootstrap: [AppComponent, HeaderComponent, FooterComponent]
})
export class AppModule {
}
