import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DefaultsService} from '../services/defaults.service';
import {Router} from "@angular/router";

declare var google: any;

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.css']
})
export class RoutesComponent implements OnInit, AfterViewInit {

  routes: any = [];
  points = [];
  clicked = null;
  page = 1;
  map: any;
  overlay: any;
  latitude = 39.8282;
  longitude = -98.5795;
  drawingManager: any;
  pageSize = 6;
  collectionSize = 0;
  select = 0;
  zoom = 16;
  edit: any;
  @ViewChild('add') add;
  addForm = {
    name: '',
    deviation: 0.5,
    points: ''
  };
  red = 'red';


  constructor(private httpService: DashrequestService, public ngbModal: NgbModal, private defaultsService: DefaultsService,
              private router: Router) {
  }

  ngOnInit(): void {

    if (localStorage.getItem('save_routes')) {
      this.points = JSON.parse(localStorage.getItem("save_routes"))
      this.ngbModal.open(this.add);
      localStorage.removeItem('save_routes')
    }
    this.httpService.getRoutes().subscribe(
      data => {
        this.routes = data;
        this.routes = this.routes.map(s => {
          s.points = s.points.split(',');
          s.points = s.points.map(w => {
            const p = w.split(':');
            return {lat: Number(p[0]), lng: Number(p[1])};
          });
          return s;
        });
      }, error => console.log(error));
    const x = this.defaultsService.getLocation();
    this.latitude = Number(x[0]);
    this.longitude = Number(x[1]);
    this.zoom = this.defaultsService.getData('defaultZoom');

  }

  changeClick(item: any) {
    const w = item.points[0];
    this.latitude = w.lat;
    this.longitude = w.lng;
    this.clicked = item.id;

  }

  get pagedobjects() {
    this.collectionSize = this.routes.length;
    return this.routes
      // .filter(s => this.search === '')
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  onRightClick(item: any) {
    return false;
  }

  onMapReady(map) {
    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon', 'polyline']
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      this.points = (event.overlay.getPath().getArray());
      // console.log(this.points)
      this.overlay = event.overlay;
      this.ngbModal.open(this.add);
    });

  }

  ngAfterViewInit(): void {
  }

  submitAddForm() {
    this.addForm.points = this.points.map(s => {
      return `${s.lat()}:${s.lng()}`;
    }).join(',');
    this.httpService.addRoute(this.addForm)
      .subscribe(s => {
        s.points = s.points.split(',');
        s.points = s.points.map(w => {
          const p = w.split(':');
          return {lat: Number(p[0]), lng: Number(p[1])};
        });
        this.overlay.setMap(null);
        this.points = [];
        this.routes.push(s);
        this.ngbModal.dismissAll();
      }, error => console.log(error));


  }

  cancel() {
    this.overlay.setMap(null);
    // this.map.rem
  }


  deleteObject() {
    this.httpService.deleteRoute(this.edit.id)
      .subscribe(data => {
        this.ngbModal.dismissAll();
        this.routes = this.routes.filter(x => x.id !== this.edit.id);
      });
  }

  passItem(item: any) {
    this.edit = Object.create(item);
  }

  updateForm() {
    this.ngbModal.dismissAll();
    this.edit.points = this.edit.points.map(s => {
      return `${s.lat}:${s.lng}`;
    }).join(',');
    this.httpService.editRoute(this.edit.id, this.edit)
      .subscribe(data => {
        this.routes = this.routes.map(s => {
          if (s.id === data.id) {
            data.points = data.points.split(',');
            data.points = data.points.map(w => {
              const p = w.split(':');
              return {lat: Number(p[0]), lng: Number(p[1])};
            });
            return data;
          }
          return s;
        });

      });

  }
}
