import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuardService} from './services/auth-guard.service';
import {LiveTrackingComponent} from './live-tracking/live-tracking.component';
import {DashComponent} from './dash/dash.component';
import {ObjectsComponent} from './objects/objects.component';
import {DriversComponent} from './drivers/drivers.component';
import {EventsComponent} from './events/events.component';
import {SubAccountsComponent} from './sub-accounts/sub-accounts.component';
import {RoutesComponent} from './routes/routes.component';
import {ZonesComponent} from './zones/zones.component';
import {AccountsComponent} from './accounts/accounts.component';
import {ConfigComponent} from './config/config.component';
import {ReportsComponent} from './reports/reports.component';
import {FAQComponent} from './faq/faq.component';
import {NewTrackingComponent} from "./new-tracking/new-tracking.component";
import {HistoryComponent} from "./history/history.component";


const routes: Routes = [
  {path: '', redirectTo: '/dash', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'faq', component: FAQComponent},
  // {path: 'register', component: RegisterComponent},
  {
    path: 'dash', component: DashboardComponent, canActivate: [AuthGuardService], children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {path: 'home', component: DashComponent},
      {path: 'live', component: LiveTrackingComponent},
      {path: 'config', component: ConfigComponent},
      {path: 'objects', component: ObjectsComponent},
      {path: 'drivers', component: DriversComponent},
      {path: 'events', component: EventsComponent},
      {path: 'sub-accounts', component: SubAccountsComponent},
      {path: 'routes', component: RoutesComponent},
      {path: 'zones', component: ZonesComponent},
      {path: 'reports', component: ReportsComponent},
      {path: 'account', component: AccountsComponent},
      {path: 'history', component: HistoryComponent},
      {path: 'beta', component: NewTrackingComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
