import {Component, OnInit} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../services/toast.service';
import {DefaultsService} from '../services/defaults.service';
import {ConverterService} from '../services/converter.service';
import {TYPES} from '../live-tracking/live-tracking.component';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  latitude = 39.8282;
  longitude = -98.5795;
  filter = 0;
  zoom = 16;
  formHistory = {
    imei: '',
    from: '',
    to: ''
  };
  interval = null;
  showPlayer = false;
  playbackSpeed = 1000;
  playerPosition = null;
  playerIndex = 0;

  lines = [];
  objects = [];


  // public lineChartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false
  // };
  //
  // public lineChartData: ChartDataSets[] = [
  //   {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'}
  // ];
  // public lineChartLabels: Label[] = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July'
  // ];
  // public lineChartColors = [
  //   {
  //     borderColor: 'black',
  //     backgroundColor: 'rgba(255,0,0,0.3)'
  //   }
  // ];
  // public lineChartLegend = true;
  // public lineChartType = 'line';
  // public lineChartPlugins = [];


  constructor(public httpService: DashrequestService,
              private modalService: NgbModal,
              private converterService: ConverterService,
              private toastService: ToastService, private defaultService: DefaultsService) {

  }

  ngOnInit(): void {
    const x = this.defaultService.getLocation();
    this.latitude = Number(x[0]);
    this.longitude = Number(x[1]);
    this.zoom = this.defaultService.getData('defaultZoom');
    this.httpService.objects.subscribe(data => {
      this.objects = data;
      let fetchIntent = localStorage.getItem('history_time');
      if (fetchIntent) {
        fetchIntent = JSON.parse(fetchIntent);
        this.formHistory.imei = fetchIntent['imei'];
        this.formHistory.to = fetchIntent['to'];
        this.formHistory.from = fetchIntent['from'];
        this.checkHistory();
        localStorage.removeItem('history_time');
      } else if (data.length > 0 && this.formHistory.imei === '') {
        this.formHistory.imei = data[0].imei;
      }
    });
    this.change();


  }

  toNum(lat: any) {
    return Number(lat);
  }

  checkHistory() {
    this.httpService.singleObjectHisory(this.formHistory.imei,
      new Date(this.formHistory.to).getTime(),
      new Date(this.formHistory.from).getTime())
      .subscribe(s => {
        this.lines = s;
        if (s.length > 0) {
          this.showPlayer = true;
          this.zoom = 30;
          this.playerIndex = 0;
          this.playerPosition = this.lines[this.playerIndex];
          this.latitude = s[0].latitude;
          this.longitude = s[0].longitude;
        } else {
          this.toastService.show('No History found on requested timeframe.', {});
        }
      }, error => console.log(error));


  }

  play() {

    if (this.interval !== null) {
      this.stopPlayer();
    }
    this.interval = setInterval(() => {
      if (this.playerIndex < this.lines.length) {
        this.playerIndex += 1;
        this.playerPosition = this.lines[this.playerIndex];
        this.latitude = this.playerPosition?this.playerPosition.latitude:0;
        this.longitude = this.playerPosition?this.playerPosition.longitude:0;
      } else {
        this.stopPlayer();
      }

    }, this.playbackSpeed);
  }

  stopPlayer() {
    clearInterval(this.interval);
  }


  saveAsRoute() {
    this.toastService.show('Coming Soon');
  }

  exportAsCSV() {

    this.converterService.downloadFile(this.lines.map(s => {
      s.serverTime = new Date(Number(s.serverTime));
      return s;
    }), new Date().toLocaleTimeString(), ['latitude', 'longitude', 'serverTime', 'course', 'accuracy']);
  }

  exportAsXlXS() {
    this.converterService.convertToXlXS(this.lines.map(({latitude, longitude, serverTime, course, accuracy}) => {
      return {latitude, longitude, serverTime: new Date(Number(serverTime)), course, accuracy};
    }));
  }

  Number(course: any) {
    return Number(course);
  }

  parseImage(imei: string) {
    try {
      return '/assets/map-icons/' + TYPES[this.objects.filter(x => x.imei === imei)[0].vehicleType] + '.png';

    } catch (e) {
      return null;
    }
  }

  datetoTimeIso = (to) => {
    return to.toISOString().split("T")[0] + "T" + to.toTimeString().split(" ")[0];
  }
  getTime = () => {
    const now = Date.now();
    return [[now, now - 3600000], [now, now - 86400000],
      [now - 86400000, now - 172800000], [now - 172800000, now - 259200000],
      [now, now - 604800000], [now, now - 2592000000]];
  }

  change() {
    const difference = this.getTime()[this.filter];
    this.formHistory.to = this.datetoTimeIso(new Date(difference[0]));
    this.formHistory.from = this.datetoTimeIso(new Date(difference[1]));
  }
}
