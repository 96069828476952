import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {NgProgressComponent} from 'ngx-progressbar';
import {LoadingService} from './services/loading.service';
import {ToastService} from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  loading = false;
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  constructor(private loadingService: LoadingService) {

  }

  ngOnInit(): void {
    this.loadingService.isLoading.subscribe(v => {
      if (this.progressBar == null) {
        return;
      }
      this.loading = v;
      v ? this.progressBar.start() : this.progressBar.complete();
    });

  }


}
