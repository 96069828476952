import {Inject, Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(public jwtHelper: JwtHelperService, @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  public isAuthenticated(): boolean {
    const token = this.storage.get('uToken');
    return token != null && !this.jwtHelper.isTokenExpired(token.token);
  }
}
