import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Const} from '../Const';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  submitted = false;
  showModal = false;
  loginRequest = {
    email: '',
    password: ''
  };
  demoRequest = {
    email: '',
    name: '',
    phone: '',
    message: '',
    recaptchaKey: ''
  };

  @ViewChild('content') content;

  em = '' || this.storage.get(Const.ERROR_MSG);

  sm = this.storage.get(Const.SUCCESS_MSG);


  constructor(private authService: AuthService, @Inject(LOCAL_STORAGE) private storage: StorageService,
              private router: Router, public ngbModal: NgbModal) {

  }

  ngOnInit() {
    this.storage.remove(Const.SUCCESS_MSG);
    this.storage.remove(Const.ERROR_MSG);
  }

  showDialog() {
    this.ngbModal.open(this.content);
  }


  onFormSubmit(data) {
    this.em = '' || this.storage.get(Const.ERROR_MSG);
    this.sm = this.storage.get(Const.SUCCESS_MSG);
    this.submitted = true;
    this.authService.login(data)
      .subscribe(rData => {
        this.storage.set('uToken', rData);
        this.router.navigate(['/dash']).then();
      }, error => {
        this.submitted = false;
        if (error.status === 401) {
          this.em = 'Username/Password not matched !';
        } else {
          this.em = error.error.reason;
        }
        // console.log(this.em);
      });
  }

  submitDemoForm() {
    this.authService.requestDemo(this.demoRequest)
      .subscribe(data => {
        this.ngbModal.dismissAll();
        this.sm = 'We will contact you via email.Thank You !';
      }, error => {
        this.ngbModal.dismissAll();
        this.em = 'Something went wrong.';
      });
  }

  resolved(key: string) {
    this.demoRequest.recaptchaKey = key;
  }
}
