import {Component, Inject} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Const} from '../Const';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  registerForm;
  submitted = false;
  registerRequest = {
    email: '',
    password: '',
    name: ''
  };
  em = '';

  constructor(private authService: AuthService, private router: Router, @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  onFormSubmit(data) {
    this.submitted = true;
    this.authService.register(data)
      .subscribe(rData => {
        this.storage.set(Const.SUCCESS_MSG, 'Registered successfully.');
        this.router.navigate(['/login']).then();
      }, error => {
        this.submitted = false;
        this.em = error.error.reason;
      });
  }


}
