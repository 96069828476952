<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Are you sure?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Deleted Objects cannot be undone !</h3>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="delete(modal)">Yes</button>
  </div>
</ng-template>

<div class="col p-0 d-flex flex-column">
  <div class="d-flex flex-column col p-0">
    <div class="d-md-flex align-items-stretch col-md p-0">
      <div class="col-md-3 p-0 border-right border-left d-md-flex flex-column bg-white mh-100">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="select" (navChange)="lines=[];">
          <li [ngbNavItem]="0">
            <a ngbNavLink><i title="Object List" class="fa fa-list"></i></a>
            <ng-template ngbNavContent>
              <div class="table-responsive table-x">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Object</th>
                    <th scope="col">Speed</th>
                    <th scope="col" rowspan="2">Status</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr *ngFor="let item of pagedobjects" (contextmenu)="onRightClick(item)" (click)="changeClick(item)"
                      class="show-pointer" [class.table-clicked]="clicked && clicked.imei===item.imei">
                    <td><b
                      style="font-size: 10px;"
                      class="badge bg-{{item.lastPosition.speed >1 && parseTime(item.lastPosition.serverTime)?'green': (item.lastPosition.serverTime) === '0'?'gray':parseTime(item.lastPosition.serverTime)?'blue':'black'}}">{{item.name}}</b>
                      <br>
                      <small style="color:black;">{{Date(item.lastPosition.serverTime)}}</small>
                    </td>
                    <td>{{item.lastPosition.speed === 0 ? 0 : Round(item.lastPosition.speed)}} kph</td>
                    <td
                      class="clr-{{item.lastPosition.speed >1 && parseTime(item.lastPosition.serverTime)?'green': (item.lastPosition.serverTime) === '0'?'gray':parseTime(item.lastPosition.serverTime)?'blue':'black'}}">
                      {{parseTime(item.lastPosition.serverTime) ? 'Online' : 'Offline'}}<br>
                    </td>
                  </tr>

                  </tbody>
                  <div *ngIf="pagedobjects.length===0" style="padding: 10px;">
                    No vehicles found.Add one from vehicles section.
                  </div>

                </table>
                <div>
                  <div class="offset-1">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </ng-template>

          </li>
          <li [ngbNavItem]="1">
            <a ngbNavLink><i title="Alerts" class="fa fa-bell"></i></a>
            <ng-template ngbNavContent>
              <div class="table-responsive table-x">
                <table id="data-table" class="table">
                  <thead>
                  <tr>
                    <th scope="col">Event Message</th>
                    <th scope="col">Type</th>
                    <th scope="col">Object</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of alerts;" (contextmenu)="onRightClick(item)" class="show-pointer">
                    <td>{{item.msg}}</td>
                    <td>{{options[item.type]}}</td>
                    <td>{{item.objectName}}
                      <br>
                      <span class="badge badge-soft-primary">{{prettyDate(item.time)}}</span>
                    </td>
                  </tr>
                  <div *ngIf="alerts.length===0" style="padding: 10px;">
                    No events yet.
                  </div>
                  </tbody>
                </table>
                <div>
                  <div class="offset-1">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
                    </ngb-pagination>
                  </div>
                </div>
              </div>

            </ng-template>
          </li>
<!--          <li [ngbNavItem]="2">-->
<!--            <a ngbNavLink><i title="Add new object" class="fa fa-book"></i></a>-->
<!--            <ng-template ngbNavContent>-->
<!--              <form (ngSubmit)="checkHistory()" style="padding: 10px;" novalidate #form="ngForm">-->
<!--                <span class="badge badge-info">History</span>-->
<!--                <div class="form-group">-->
<!--                  <label>Vehicle</label>-->
<!--                  <select-->
<!--                    [class.is-invalid]="object.touched&&object.invalid"-->
<!--                    #object="ngModel"-->
<!--                    [(ngModel)]="formHistory.imei" required name="vehicleType1" class="form-control">-->
<!--                    <option [value]="item.imei" *ngFor="let item of this.objects">{{item.name}}</option>-->
<!--                  </select>-->
<!--                  <small *ngIf="object.touched&&object.invalid" class="alert-danger p-1">Enter a valid object</small>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <label>From Date</label>-->
<!--                  <input [(ngModel)]="formHistory.from" name="from-date" required="required" class="form-control"-->
<!--                         type="datetime-local"/>-->
<!--                </div>-->
<!--                <div class="form-group">-->
<!--                  <label>To Date</label>-->
<!--                  <input [(ngModel)]="formHistory.to" name="to-date" required="required" class="form-control"-->
<!--                         type="datetime-local"/>-->
<!--                </div>-->
<!--                <div class="form-group" style="display: flex;justify-content: center;">-->
<!--                  <button [disabled]="form.form.invalid||form.form.pristine"-->
<!--                          class="btn btn-primary"> View History-->
<!--                  </button>-->
<!--                </div>-->

<!--              </form>-->
<!--            </ng-template>-->
<!--          </li>-->
          <li [ngbNavItem]="3" [disabled]="true" title="Object History">
            <a ngbNavLink><i title="Object History" class="fa fa-history"></i></a>
            <ng-template ngbNavContent>
              <div style="padding: 10px;">
                <form (ngSubmit)="historyForm()" novalidate #form="ngForm"
                      style="padding: 10px;">
                  <span class="badge badge-info">{{edit.name}}'s History</span>
                  <div class="form-group">
                    <label>IMEI</label>
                    <input [disabled]="true" [class.is-invalid]="imei.touched&&imei.invalid" #imei="ngModel"
                           [(ngModel)]="edit.imei" required type="text" class="form-control" name="imei"
                           aria-describedby="emailHelp" placeholder="Enter imei">
                  </div>
                  <div class="form-group">
                    <label>History Time</label>
                    <select [class.is-invalid]="history.touched&&history.invalid" #history="ngModel"
                            [(ngModel)]="historyTime" required name="vehicleType" class="form-control">
                      <option value="0">Last Hour</option>
                      <option value="1">Today</option>
                      <option value="2">Yesterday</option>
                      <option value="3">Before 2 days</option>
                      <option value="4">Before 3 days</option>
                      <option value="5">This Month</option>
                    </select>
                  </div>
                  <button [disabled]="form.form.invalid" class="btn btn-primary"><i class="fa fa-history"></i> Show
                    History
                  </button>
                </form>
              </div>

            </ng-template>
          </li>
          <li [ngbNavItem]="4" [disabled]="true">
            <a ngbNavLink><i title="Editing a object" class="fa fa-edit"></i></a>
            <ng-template ngbNavContent>
              <div style="padding: 10px;">
                <form (ngSubmit)="updateForm()" style="padding: 10px;" novalidate #form="ngForm">
                  <span class="badge badge-info">Edit {{edit.name}}</span>
                  <div class="form-group">
                    <label>IMEI</label>
                    <input [class.is-invalid]="imei.touched&&imei.invalid" #imei="ngModel" [(ngModel)]="edit.imei"
                           required type="text" class="form-control" name="imei" aria-describedby="emailHelp"
                           placeholder="Enter imei">
                    <small *ngIf="imei.touched&&imei.invalid" class="alert-danger p-1">Enter a valid imei</small>
                  </div>
                  <div class="form-group">
                    <label>Name</label>
                    <input #name [class.is-invalid]="name.touched&&name.invalid" name="name" #name="ngModel"
                           [(ngModel)]="edit.name" required type="name" class="form-control"
                           placeholder="Enter object name">
                    <small *ngIf="name.touched&&name.invalid" class="alert-danger p-1">Enter a valid name</small>
                  </div>
                  <div class="form-group">
                    <label>Vehicle Type</label>
                    <select [class.is-invalid]="vehicleType.touched&&vehicleType.invalid" #vehicleType="ngModel"
                            [(ngModel)]="edit.vehicleType" required name="vehicleType" class="form-control">
                      <option value="0">Car</option>
                      <option value="1">Bus</option>
                      <option value="2">Bike</option>
                      <option value="3">Truck</option>
                    </select>
                    <small *ngIf="vehicleType.touched&&vehicleType.invalid" class="alert-danger p-1">Enter a valid
                      vehicle
                      type</small>
                  </div>
                  <div class="form-group">
                    <button [disabled]="form.form.invalid||form.form.pristine" class="btn btn-primary"><i
                      class="fa fa-check-circle"></i> Update
                    </button>
                    &nbsp;
                    <button (click)="nav.select(0)" type="button" class="btn btn-danger"><i
                      class="fa fa-times-circle"></i> Cancel
                    </button>
                  </div>

                </form>

              </div>

            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

      </div>
      <div style="position: relative;"
           class="col-md pl-2 pr-0 d-flex flex-column align-items-stretch map-container mt-3 mt-md-0">
        <div *ngIf="showPlayer && select===3" class="card"
             style="padding: 10px;position:absolute;bottom:-25px;z-index: 9999;width: 100%;">
          <div style="display: flex;font-weight: bold;">
            <b style="flex: 1;">Vehicle History Playback</b>
          </div>
          <hr style="margin: 0;padding: 10px;"/>
          <b>Playback Speed:
            <select [value]="playbackSpeed">
              <option value="150">1x</option>
              <option value="50">4x</option>
              <option value="30">5x</option>
            </select>
          </b>
          <br/>
          <div style="display: flex;">
            <button (click)="play()" class="btn btn-primary">Play</button>
            &nbsp;&nbsp;
            <button (click)="stopPlayer()" class="btn btn-secondary">Stop</button>
            &nbsp;&nbsp;
            <button (click)="saveAsRoute()" class="btn btn-primary">Export as Route</button>
          </div>
        </div>
        <div *ngIf="showInfo && select!=3" class="card"
             style="padding: 10px;position:absolute;bottom:-25px;z-index: 9999;width: 100%;">
          <div style="display: flex;">
            <div style="flex: 1;">
              <div style="display: flex;font-weight: bold;">
                <b style="flex: 1;">Vehicle Information</b>
              </div>
              <hr style="margin: 0;"/>
              <b>Vehicle Name: {{clicked.imei}}</b>
              <br>
              <b>Position: Near BhimsenGola Chowk</b>
              <br>
              <b>Latitude: {{Round(clicked.lastPosition.latLng.split(":")[0])}}</b>
              <br>
              <b>Longitude: {{Round(clicked.lastPosition.latLng.split(":")[1])}}</b>
            </div>
            <div style="height: 100px;width: 2px;margin-right: 10px;margin-left: 10px; background: #efefef;"></div>
            <div style="flex: 1">
              <div style="display: flex;font-weight: bold;">
                <b style="flex: 1;">Vehicle Status</b>
              </div>
              <hr style="margin: 0;"/>
              
              <b>Battery: {{clicked.attributes&&clicked.attributes.batteryLevel}}</b>
              <br>
              <b>Ignition: {{clicked.attributes&&clicked.attributes.ignition ? 'On' : 'Off'}}</b>
              <br>
              <b>Motion: {{clicked.attributes&&clicked.attributes.motion ? 'On' : 'Off'}}</b>
              <br>
              <b>Total Distance: {{Round(clicked.attributes?clicked.attributes.totalDistance / 1000:0)}} km</b>
            </div>

            <button class="btn btn-primary" (click)="showInfo=false;">
              <i style="z-index: 999;" class="fa fa-times-circle"></i>
            </button>
          </div>
        </div>
        <agm-map [streetViewControl]="false" [mapTypeControl]="false" [latitude]="latitude" [longitude]="longitude"
                 [zoom]="zoom">

          <agm-overlay [latitude]="mark.lastPosition.latLng.split(':')[0]"
                       [longitude]="mark.lastPosition.latLng.split(':')[1]" *ngFor="let mark of pagedobjects">

            <img *ngIf="mark.lastPosition.latLng!='0:0'" (click)="onRightClick(mark)"
                 [style]="'height:30px;transform:rotate('+(270+Number(mark.lastPosition.angle))+'deg);'"
                 [src]="parseImages(mark)" alt="marker">
          </agm-overlay>

          <ng-container *ngFor="let mark of pagedobjects">
            <agm-polyline-point *ngFor="let point of mark.lastLocations" [latitude]="toNum(point.lat)"
                                [longitude]="toNum(point.lon)">
            </agm-polyline-point>
          </ng-container>
          <agm-polygon fillColor="#00ff001C" *ngFor="let item of routes" [paths]="item.points">
          </agm-polygon>
          <agm-circle *ngFor="let item of zones" [fillColor]="item.color" [(radius)]="item.radius" [latitude]="item.lat"
                      [longitude]="item.lng">
          </agm-circle>
          <ng-container *ngIf="playerPosition&&lines.length>0&&(select===3||select===2)">
            <agm-overlay [latitude]="playerPosition.latitude" [longitude]="playerPosition.longitude">
              <img [style]="'transform:rotate('+playerPosition.course+'deg);'" src="/assets/images/marker.svg"
                   alt="marker">
            </agm-overlay>
            <agm-overlay [latitude]="playerPosition.latitude" [longitude]="playerPosition.longitude">
              <div class="card" style="background: #fff;margin: 15px;padding: 10px;">
                <b>{{Date(playerPosition.serverTime)}}</b>
                <!--                 {{log(playerPosition)}}-->
              </div>
            </agm-overlay>
            <agm-polyline>
              <agm-polyline-point *ngFor="let point of lines" [latitude]="toNum(point.latitude)"
                                  [longitude]="toNum(point.longitude)">
                <agm-overlay *ngIf="point.parked" [latitude]="point.latitude" [longitude]="point.longitude">
                  <img style="width: 20px;height: 20px;" src="https://www.anytrack.live/img/markers/route-stop.svg"/>
                </agm-overlay>
              </agm-polyline-point>
            </agm-polyline>
          </ng-container>
        </agm-map>
      </div>
    </div>

  </div>
</div>
