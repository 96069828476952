<meta charset="utf-8"/>
<title></title>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<meta content="A fully featured admin theme which can be used to build CRM, CMS, etc." name="description"/>
<meta content="Coderthemes" name="author"/>
<meta http-equiv="X-UA-Compatible" content="IE=edge"/>

<!-- App favicon -->
<link rel="shortcut icon" href="/assets/images/favicon.ico">

<!-- App css -->
<link href="/assets/css/bootstrap.min.css" rel="stylesheet" type="text/css"/>
<link href="/assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
<link href="/assets/libs/flatpickr/flatpickr.min.css" rel="stylesheet" type="text/css"/>
<link href="/assets/css/app.min.css" rel="stylesheet" type="text/css"/>
<link href="/assets/css/fa.css" rel="stylesheet" type="text/css"/>
<link href="/assets/libs/select2/select2.min.css" rel="stylesheet" type="text/css"/>
<link href="/assets/libs/multiselect/multi-select.css" rel="stylesheet" type="text/css"/>

<div id="preloader">
  <div id="status">
    <div class="spinner">
      <div class="circle1"></div>
      <div class="circle2"></div>
      <div class="circle3"></div>
    </div>
  </div>
</div>
