<ng-template #delete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title3">Are you sure?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Deleted Objects cannot be undone !</h3>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="deleteObject()">Yes</button>
  </div>
</ng-template>
<ng-template #add let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Add a Zone</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form novalidate #form="ngForm">
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="addForm.name"
               required type="text"
               class="form-control" placeholder="Enter Zone name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Color</label>
        <ng-color-box name="color" [ngModel]="lol" (ngModelChange)="colorChange($event)"
                      startHex="23bf26"></ng-color-box>
      </div>
      <div class="form-group">
        <label>Radius</label>
        <input [class.is-invalid]="deviation.touched&&deviation.invalid" name="deviation" #deviation="ngModel"
               [(ngModel)]="addForm.radius"
               required type="number"
               class="form-control" placeholder="Enter radius">
        <small *ngIf="deviation.touched&&deviation.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Center</label>
        <div class="row col-md-12">
          <input
            name="center"
            [(ngModel)]="addForm.points"
            required
            type="text"
            class="form-control col-sm-9"
            placeholder="Enter Points">
        </div>
      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel();modal.dismiss()">Cancel</button>
    <button [disabled]="form.form.invalid||form.form.pristine" (click)="submitAddForm()"
            class="btn btn-outline-secondary"><i
      class="fa fa-plus-circle"></i> Add
    </button>

  </div>
</ng-template>
<ng-template #editForm let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title">Edit a Zone</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form novalidate #form="ngForm">
      <div class="form-group">
        <label>Name</label>
        <input [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="edit.name"
               required type="text"
               class="form-control" placeholder="Enter Zone name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Color</label>
        <ng-color-box name="color" [ngModel]="lol" (ngModelChange)="colorChange($event,'E')"
                      [startHex]="edit.color"></ng-color-box>
      </div>
      <div class="form-group">
        <label>Radius</label>
        <input [class.is-invalid]="deviation.touched&&deviation.invalid" name="deviation" #deviation="ngModel"
               [(ngModel)]="edit.radius"
               required type="number"
               class="form-control" placeholder="Enter deviation name">
        <small *ngIf="deviation.touched&&deviation.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Center</label>
        <div class="row col-md-12">
          <input
            name="center"
            [(ngModel)]="edit.points"
            required
            type="text"
            class="form-control col-sm-9"
            placeholder="Enter Points">
        </div>
      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button [disabled]="form.form.invalid||form.form.pristine" (click)="updateForm()"
            class="btn btn-outline-secondary"><i
      class="fa fa-cloud-download-alt"></i> Update
    </button>

  </div>
</ng-template>

<div class="col p-0 d-flex flex-column">
  <div class="d-flex flex-column col p-0">
    <div class="d-md-flex align-items-stretch col-md p-0">
      <div class="col-md-3 p-0 border-right border-left d-md-flex flex-column bg-white mh-100">
        <span class="badge badge-primary">Zones</span>
        <div class="table-responsive table-x">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Radius</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let item of pagedobjects" (contextmenu)="onRightClick(item)"
                (click)="changeClick(item)"
                class="show-pointer"
                [class.table-clicked]="clicked===item.id">
              <td>{{item.name}}</td>
              <td>{{item.radius}}</td>
              <td><a (click)="passItem(item);ngbModal.open(editForm)"><i class="fa fa-edit"></i></a><a
                (click)="passItem(item);ngbModal.open(delete)">&nbsp;<i
                class="fa fa-trash"></i></a></td>
            </tr>
            </tbody>
            <div *ngIf="pagedobjects.length===0" style="padding: 10px;">
              No zones found.
            </div>
          </table>
          <div>
            <div class="offset-1">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
              </ngb-pagination>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md pl-2 pr-0 d-flex flex-column align-items-stretch map-container mt-3 mt-md-0">
        <agm-map  [latitude]="latitude" [zoom]="zoom" [longitude]="longitude" (mapReady)="onMapReady($event)">
          <agm-circle *ngFor="let item of zones" [fillColor]="item.color" [(radius)]="item.radius" [latitude]="item.lat" [longitude]="item.lng">

          </agm-circle>
        </agm-map>
      </div>
    </div>

  </div>
</div>
