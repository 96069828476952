import {Component, OnInit} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {DefaultsService} from '../services/defaults.service';
import {Track} from '../live-tracking/live-tracking.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  addForm = {
    name: '',
    objects: [],
    type: 1,
    values: {
      tPeriod: 300,
      routeId: '',
      zoneId: '',
      speedLimit: 10,
      parametersAndSensors: []
    }
  };
  events: any[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  search: '';
  edit: any;
  totalObjects: Track[];
  objects = [];
  selectedObjects = [];
  routes: any;
  zones: any;
  loading = true;
  options = ['SOS', 'Bracelet On', 'Bracelet Off', 'Man Down', 'Shock', 'Tow', 'Power cut', 'Gps antenna cut', 'Signal jamming',
    'Low DC', 'Low Battery', 'Connection:Yes', 'Connection:No', 'Gps:Yes', 'Gps:No', 'Stopped', 'Moving', 'Engine Idle', 'Over ' +
    'Speed', 'Under Speed', 'Harsh Acceleration', 'Harsh Breaking', 'Harsh Cornering', 'Parameter', 'Sensor', 'Service', 'DTC' +
    ' (Diagnostic Trouble Codes)', 'Route In', 'Route Out', 'Zone In', 'Zone Out'];

  constructor(public httpService: DashrequestService, public ngbModal: NgbModal) {
  }


  ngOnInit(): void {
    this.httpService.getEvents()
      .subscribe(data => {
        this.collectionSize = data.length;
        this.events = data;
      }, error => console.log(error));

    this.httpService.objects.subscribe(data => {
      this.totalObjects = data;
    });
  }

  get pagedobjects() {
    this.collectionSize = this.events.length;
    return this.events
      // .filter(s => this.search === '')
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  rightClick() {
    return false;
  }

  editObject(item: any) {
    this.showAdd();
    this.edit = Object.create(item);
    this.selectedObjects = this.edit.objects.map(s => {
      return {name: this.Parse(s), id: s};
    });
    this.loading = true;
    this.httpService.getRoutes()
      .subscribe(routes => {
        this.routes = routes;
        this.httpService.getZones().subscribe(evt => {
          this.zones = evt;
          this.loading = false;
        });
      });
  }

  deleteNow(item) {
    this.edit = item;
  }

  submitAddForm() {
    this.addForm.objects = this.selectedObjects.map(s => s.id);
    this.httpService.addEvent(this.addForm)
      .subscribe(data => {
        this.selectedObjects = [];
        this.addForm.name = '';
        this.events.push(data);
        this.ngbModal.dismissAll();
      }, error => console.log(error));
  }


  showAdd() {
    this.objects = this.totalObjects.map(s => {
      return {name: s.name, id: s.id};
    });
    this.loading = true;
    this.httpService.getRoutes()
      .subscribe(routes => {
        this.routes = routes;
        this.httpService.getZones().subscribe(evt => {
          this.zones = evt;
          this.loading = false;
        });
      });
  }

  Parse(object) {
    return this.totalObjects.filter(s => s.id === object)[0].name;
  }

  updateForm() {
    this.edit.objects = this.selectedObjects.map(s => s.id);
    this.httpService.editEvent(this.edit.id, this.edit)
      .subscribe(d => {
        this.events = this.events.map(s => s.id === d.id ? d : s);
        this.ngbModal.dismissAll();
      }, error => console.log(error));

  }

  deleteObject() {
    this.httpService.deleteEvent(this.edit.id)
      .subscribe(d => {
        this.events = this.events.filter(s => s.id !== this.edit.id);
        this.ngbModal.dismissAll();

      }, error => console.log(error));
  }

  addOtherInfo(z = 0) {
    if (z === 1) {
      return this.edit.values.parametersAndSensors.push([{name: '', value: ''}]);
    }
    this.addForm.values.parametersAndSensors.push([{name: '', value: ''}]);
  }

  deleteOtherInfo(z = 0) {
    if (z === 1) {
      return this.edit.values.parametersAndSensors = this.edit.values.parametersAndSensors.filter(it => it.name !== 'wisd_x');
    }
    this.addForm.values.parametersAndSensors = this.addForm.values.parametersAndSensors.filter(it => it.name !== 'wisd_x');
  }


  Number(type: number) {
    return Number(type);
  }
}
