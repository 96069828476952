<form class="form-horizontal" novalidate>
  <h4 class="modal-title" id="modal-basic-title">Configurations</h4>
  <br>
  <div class="form-group">
    <label>Default Map Location</label>
    <agm-map (mapClick)="update($event)" [zoom]="data.zoom" [latitude]="data.lat" [longitude]="data.lon"
             style="height: 200px;">
      <agm-marker [latitude]="data.lat" [longitude]="data.lon"></agm-marker>
    </agm-map>
  </div>
  <div class="form-group">
    <label>Default Zoom</label>
    <input type="number" name="zoom" class="form-control" required
           placeholder="Enter Default Zoom" #zoom="ngModel"
           [(ngModel)]="data.zoom">
  </div>
  <div class="form-group">
    <label>Online Time(For Detecting device online)</label>
    <input
      required
      type="number" name="online" class="form-control" placeholder="Enter Default Online Time"
      #online="ngModel"
      [(ngModel)]="data.onlineTime">
  </div>
  <div class="form-group">
    <label>Default Expiry Date</label>
    <input type="date"
           name="expiryD" class="form-control" placeholder="Enter Default ExpiryDate"
           required
           #expiry="ngModel"
           [(ngModel)]="data.expiryDate">
  </div>

  <div class="form-group">
    <button class="btn btn-primary" (click)="updateNow()">Update</button>
  </div>
</form>
