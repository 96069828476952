import {Component, OnInit, ViewChild} from '@angular/core';
import {DashrequestService} from '../services/dashrequest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DefaultsService} from '../services/defaults.service';

declare var google: any;

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})
export class ZonesComponent implements OnInit {

  zones: any = [];
  clicked = null;
  page = 1;
  map: any;
  overlay: any;
  pageSize = 6;
  latitude = 39.8282;
  longitude = -98.5795;
  collectionSize = 0;
  select = 0;
  zoom = 16;
  edit: any;
  @ViewChild('add') add;
  addForm = {
    name: '',
    color: '#000',
    radius: 0.5,
    points: ''
  };
  red = 'red';
  lol: any;


  constructor(private httpService: DashrequestService, public ngbModal: NgbModal, private defaultsService: DefaultsService) {
  }

  ngOnInit(): void {
    this.httpService.getZones().subscribe(
      data => {
        this.zones = data.map(s => {
          const x1 = s.points.split(':');
          s.lat = Number(x1[0]);
          s.lng = Number(x1[1]);
          return s;
        });
      });
    const x = this.defaultsService.getLocation();
    this.latitude = Number(x[0]);
    this.longitude = Number(x[1]);
    this.zoom = this.defaultsService.getData('defaultZoom');
  }

  changeClick(item: any) {
    console.log(item);
    this.clicked = item.id;
    this.latitude = item.lat;
    this.longitude = item.lng;
  }

  get pagedobjects() {
    this.collectionSize = this.zones.length;
    return this.zones
      // .filter(s => this.search === '')
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  onRightClick(item: any) {
    return false;
  }

  onMapReady(map) {
    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['circle']
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      if (event.type === 'circle') {
        this.addForm.radius = event.overlay.getRadius();
        this.addForm.points = event.overlay.getCenter();
      } else {
        event.overlay.setMap(null);
        return;
      }
      this.overlay = event.overlay;
      this.ngbModal.open(this.add);
    });

  }


  submitAddForm() {
    // @ts-ignore
    this.addForm.points = this.addForm.points.lat() + ':' + this.addForm.points.lng();
    this.httpService.addZone(this.addForm)
      .subscribe(s => {
        this.overlay.setMap(null);
        const x = s.points.split(':');
        s.lat = Number(x[0]);
        s.lng = Number(x[1]);
        this.zones.push(s);
        this.ngbModal.dismissAll();
      }, error => console.log(error));

  }

  cancel() {
    this.overlay.setMap(null);
    // this.map.rem
  }


  deleteObject() {
    this.httpService.deleteZone(this.edit.id)
      .subscribe(data => {
        this.ngbModal.dismissAll();
        this.zones = this.zones.filter(x => x.id !== this.edit.id);
      }, err => console.log(err));
  }

  passItem(item: any) {
    this.edit = Object.create(item);
  }

  updateForm() {
    this.ngbModal.dismissAll();
    this.httpService.editZone(this.edit.id, this.edit)
      .subscribe(data => {
        this.zones = this.zones.map(s => {
          if (s.id === this.edit.id) {
            const x = data.points.split(':');
            data.lat = Number(x[0]);
            data.lng = Number(x[1]);
            return data;
          }
          return s;
        });
      });
  }

  colorChange($event: any, w = 'A') {
    if (w === 'A') {
      this.addForm.color = $event.hexString;
    } else {
      this.edit.color = $event.hexString;
    }
  }
}
