import {Component, Inject, OnInit} from '@angular/core';
import {$e} from 'codelyzer/angular/styles/chars';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Const} from '../Const';
import {DefaultsService} from '../services/defaults.service';
import {ToastService} from '../services/toast.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  data = {
    lat: 27.669995666666665,
    lon: 85.3204645,
    zoom: 16,
    onlineTime: 60,
    expiryDate: '2029-04-06'
  };

  constructor(private defaultService: DefaultsService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    const x = this.defaultService.getLocation();
    this.data.lat = Number(x[0]);
    this.data.lon = Number(x[1]);
    this.data.zoom = this.defaultService.getData('defaultZoom');
    this.data.onlineTime = this.defaultService.getData('defaultOnlineTime');
    this.data.expiryDate = this.defaultService.getData('defaultExpiryDate');
  }

  update($event: any) {
    this.data.lat = $event.coords.lat;
    this.data.lon = $event.coords.lng;
    this.defaultService.setData('defaultLocation', `${this.data.lat}:${this.data.lon}`);
  }

  updateNow() {
    this.defaultService.setData('defaultZoom', this.data.zoom);
    this.defaultService.setData('defaultOnlineTime', this.data.onlineTime);
    this.defaultService.setData('defaultExpiryDate', this.data.expiryDate);
    this.toastService.show('Updated !');
  }
}
