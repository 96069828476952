<ng-template #content let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add a Vehicle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form novalidate #form="ngForm">

      <div class="custom-control custom-switch mb-2">
        <input type="checkbox" class="custom-control-input" id="customSwitch1" name="isActive"
               [(ngModel)]="addForm.isActive" checked>
        <label class="custom-control-label" for="customSwitch1">Is active?</label>
      </div>
      <div class="form-group">
        <label>IMEI</label>
        <input [class.is-invalid]="imei.touched&&imei.invalid" #imei="ngModel" [(ngModel)]="addForm.imei" required
               type="text" class="form-control" name="imei" aria-describedby="emailHelp" placeholder="Enter imei">
        <small *ngIf="imei.touched&&imei.invalid" class="alert-danger p-1">Enter a valid imei</small>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input #name [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="addForm.name" required type="text" class="form-control" placeholder="Enter vehicle name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <input (change)="addForm.name=addForm.imei" [checked]="false" type="checkbox">
        Copy from IMEI
      </div>
      <div class="form-group">
        <label>Sim No</label>
        <input pattern="9?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?"
               [class.is-invalid]="sim.touched&&sim.invalid" name="sim" #sim="ngModel" [(ngModel)]="addForm.simNo"
               required
               type="number" class="form-control" placeholder="Enter simcard number">
        <small *ngIf="sim.touched&&sim.invalid" class="alert-danger p-1">Enter a valid number</small>
      </div>
      <div *ngIf="addForm.isActive" class="form-group">
        <label>Expiry Date</label>
        <input [class.is-invalid]="dd.touched&&dd.invalid" name="dd" #dd="ngModel" [(ngModel)]="addForm.disablingDate"
               required type="date" class="form-control" placeholder="Enter simcard number">
        <small *ngIf="dd.touched&&dd.invalid" class="alert-danger p-1">Enter a valid daate</small>
      </div>
      <div class="form-group">
        <label>Vehicle Type</label>
        <select [class.is-invalid]="vehicleType.touched&&vehicleType.invalid" #vehicleType="ngModel"
                [(ngModel)]="addForm.vehicleType" required name="vehicleType" class="form-control">
          <option value="0">Car</option>
          <option value="1">Bus</option>
          <option value="2">Bike</option>
          <option value="3">Truck</option>
        </select>
        <small *ngIf="vehicleType.touched&&vehicleType.invalid" class="alert-danger p-1">Enter a valid vehicle
          type</small>
      </div>
      <div class="form-group">
        <label>Fuel Type</label>
        <select [class.is-invalid]="fuelType.touched&&fuelType.invalid" #fuelType="ngModel"
                [(ngModel)]="addForm.fuelType" required name="fueltype" class="form-control">
          <option value="0">Petrol</option>
          <option value="1">Disel</option>
        </select>
      </div>
      <div class="form-group">
        <label>Other Information (If you want to add)</label>
        <br>
        <div style="margin-top: 2px;" class="row" *ngFor="let item of addForm.attributes;let i=index;">
          <div class="col-sm-5">
            <input name="attrib_{{i}}" [(ngModel)]="item[0]" required type="text" class="form-control"
                   placeholder="Enter field">
          </div>
          <div class="col-sm-6">
            <input [(ngModel)]="item[1]" required type="text" name="value_{{i}}" #v_{{i}} class="form-control"
                   placeholder="Enter value">
          </div>
          <button (click)="item[0]='wisd_x';deleteOtherInfo()" class="btn btn-danger"><i class="fa fa-trash"></i>
          </button>
        </div>
        <br>
        <button type="button" (click)="addOtherInfo()" class="btn btn-primary">Add <i class="fa fa-plus-circle"></i>
        </button>

        <hr/>
        <div style="padding: 10px;border:3px solid red;margin:10px;margin-bottom: 20px;">
          <i class="fa fa-info-circle"></i>&nbsp;
          <b> Make sure vehicle is pointed towards sawari's server. Don't know how to do that? <a
            style="font-size: 18px;" target="_blank"
            href="/faq#point-device-server">Click
            here</a></b>
        </div>

      </div>

    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="submitAddForm()" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i class="fa fa-plus-circle"></i> Add Vehicle
    </button>

  </div>
</ng-template>
<ng-template #delete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title3">Are you sure?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Deleted Vehicles cannot be undone !</h3>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="deleteObject()">Yes</button>
  </div>
</ng-template>

<ng-template #editItem let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title1">Edit {{edit.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form novalidate #form="ngForm">
      <div class="custom-control custom-switch mb-2">
        <input type="checkbox" class="custom-control-input" id="customSwitch2" name="isActive"
               [(ngModel)]="edit.isActive" checked>
        <label class="custom-control-label" for="customSwitch2">Is active?</label>
      </div>
      <div class="form-group">
        <label>IMEI</label>
        <input [class.is-invalid]="imei.touched&&imei.invalid" #imei="ngModel" [(ngModel)]="edit.imei" required
               type="text" class="form-control" name="imei" aria-describedby="emailHelp" placeholder="Enter imei">
        <small *ngIf="imei.touched&&imei.invalid" class="alert-danger p-1">Enter a valid imei</small>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input #name [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="edit.name" required type="text" class="form-control" placeholder="Enter vehicle name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <input (change)="edit.name=edit.imei" [checked]="false" type="checkbox">
        Copy from IMEI
      </div>
      <div class="form-group">
        <label>Sim No</label>
        <input pattern="9?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?"
               [class.is-invalid]="sim.touched&&sim.invalid" name="sim" #sim="ngModel" [(ngModel)]="edit.simNo" required
               type="number" class="form-control" placeholder="Enter simcard number">
        <small *ngIf="sim.touched&&sim.invalid" class="alert-danger p-1">Enter a valid number</small>
      </div>
      <div class="form-group">
        <label>Fuel Type</label>
        <select [class.is-invalid]="fuelType.touched&&fuelType.invalid" #fuelType="ngModel" [(ngModel)]="edit.fuelType"
                required name="fueltype" class="form-control">
          <option value="0">Petrol</option>
          <option value="1">Disel</option>
        </select>
      </div>
      <div *ngIf="edit.isActive" class="form-group">
        <label>Expiry Date</label>
        <input [class.is-invalid]="dd.touched&&dd.invalid" name="dd" #dd="ngModel" [(ngModel)]="disablingDate" required
               type="date" class="form-control" placeholder="Enter simcard number">
        <small *ngIf="dd.touched&&dd.invalid" class="alert-danger p-1">Enter a valid daate</small>
      </div>
      <div class="form-group">
        <label>Vehicle Type</label>
        <select [class.is-invalid]="vehicleType.touched&&vehicleType.invalid" #vehicleType="ngModel"
                [(ngModel)]="edit.vehicleType" required name="vehicleType" class="form-control">
          <option value="0">Car</option>
          <option value="1">Bus</option>
          <option value="2">Bike</option>
          <option value="3">Truck</option>
        </select>
        <small *ngIf="vehicleType.touched&&vehicleType.invalid" class="alert-danger p-1">Enter a valid vehicle
          type</small>
      </div>
      <div class="form-group">
        <label>Other Info</label>
        <br>
        <div style="margin-top: 2px;" class="row" *ngFor="let item of edit.attributes;let i=index;">
          <div class="col-sm-5">
            <input name="attrib_{{i}}" [(ngModel)]="item[0]" required type="text" class="form-control"
                   placeholder="Enter field">
          </div>
          <div class="col-sm-6">
            <input [(ngModel)]="item[1]" required type="text" name="value_{{i}}" #v_{{i}} class="form-control"
                   placeholder="Enter value">
          </div>
          <button (click)="item[0]='wisd_x';deleteOtherInfo('E')" class="btn btn-danger"><i class="fa fa-trash"></i>
          </button>
        </div>
        <br>
        <button type="button" (click)="addOtherInfo('E')" class="btn btn-primary">Add <i class="fa fa-plus-circle"></i>
        </button>
      </div>

    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="updateForm()" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i class="fa fa-upload"></i>Update Vehicle
    </button>

  </div>
</ng-template>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h5 class="card-title mt-0 mb-0 header-title">Vehicles</h5>
        <div class="float-sm-right mt-3 mt-sm-0">
          <div class="task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
            <form>
              <div class="input-group">
                <input name="search" [(ngModel)]="search" type="text" class="form-control search-input"
                       placeholder="Search..."/>
                <span class="uil uil-search icon-search"></span>
                <div class="input-group-append">
                  <button (click)="ngbModal.open(content)" class="btn btn-soft-primary" type="button">
                    <i class='uil uil-plus-circle'></i> Add Vehicle
                  </button>
                  <button (click)="this.httpService.exportTable('data-table')"
                          class="btn btn-soft-secondary"><i class="fa fa-file-export"></i> Export as Excel Sheet
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table id="data-table" class="table table-hover table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Name</th>
          <th scope="col">IMEI</th>
          <th scope="col">SimCard No</th>
          <th scope="col">Ignition</th>
          <th scope="col">Total Distance</th>
          <th scope="col">Status</th>
          <th scope="col">Last Connection</th>
          <th scope="col">Expires On</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr (contextmenu)="rightClick()" *ngFor="let item of pagedobjects;let i=index;">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.imei}}</td>
          <td>{{item.simNo}}</td>
          <td>{{item.attributes&&item.attributes.ignition ? 'On' : 'Off'}}</td>
          <td>{{Round(item.attributes?item.attributes.totalDistance / 1000:0)}} km</td>
          <td>{{item.isActive ? 'Active' : 'Expired'}}</td>
          <td><span
            class="badge badge-soft-{{item.isActive?'primary':'danger'}} py-1">{{Date(item.lastPosition.serverTime)}}</span>
          </td>
          <td>{{Date(item.disablingDate.toString())}}</td>
          <td><a (click)="editObject(item);ngbModal.open(editItem)"><i class="fa fa-edit"></i></a><a
            (click)="deleteNow(item);ngbModal.open(delete)">&nbsp;<i class="fa fa-trash"></i></a></td>
        </tr>
        <div *ngIf="pagedobjects.length===0" style="padding: 10px;">
          No Vehicles Found.
        </div>
        </tbody>
      </table>
    </div>
    <br>
    <div>
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
      </ngb-pagination>
    </div>

  </div> <!-- end card-body-->
</div> <!-- end card-->
<button (click)="ngbModal.open(content)" title="add a route" class="btn-secondary btn btn-rounded"
        style="z-index:123;margin: 10px;position: fixed;bottom: 0;right: 0;"><i class="fa fa-plus-circle"></i></button>
