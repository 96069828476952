<ng-template #report let-modal class="modal-dialog-scrollable">
  <div class="modal-header"><h4>Preview Report</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive mt-4" [innerHTML]="html">

    </div>
  </div>

</ng-template>

<ng-template #addReport let-modal class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{preview ? 'Report Preview' : 'Add a Report'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form novalidate #form="ngForm">
      <div class="form-group" *ngIf="!preview">
        <label>Name</label>
        <input #name [class.is-invalid]="name1.touched&&name1.invalid" name="name" #name1="ngModel"
               [(ngModel)]="addForm.name"
               required type="text"
               class="form-control" placeholder="Enter report name">
        <small *ngIf="name1.touched&&name1.invalid" class="alert-danger p-1">Enter a valid name</small>
      </div>
      <div class="form-group">
        <label>Report Type</label>
        <select required="required" class="form-control" [(ngModel)]="addForm.type" name="type">
          <option value="gI">General Information</option>
        </select>
      </div>
      <div class="form-group">
        <label>Objects</label>
        <ng-select
          [items]="objects"
          [multiple]="true"
          [selectOnTab]="true"
          [closeOnSelect]="true"
          [searchable]="true"
          bindLabel="name"
          name="objects"
          placeholder="Select Objects"
          [(ngModel)]="selectedObjects">
        </ng-select>
      </div>
      <div *ngIf="addForm.repetition==='4'||addForm.repetition==='0'||addForm.repetition==='5'" class="form-group">
        <label>From Date</label>
        <input required="required" class="form-control" type="date" name="beforeTime" [(ngModel)]="addForm.bTime">
      </div>
      <div *ngIf="addForm.repetition==='4'||addForm.repetition==='0'||addForm.repetition==='5'" class="form-group">
        <label>To Date</label>
        <input required="required" class="form-control" type="date" name="afertTime" [(ngModel)]="addForm.aTime">
      </div>
      <div *ngIf="addForm.repetition=='4'" class="form-group">
        <label>Send On </label>
        <input required="required" class="form-control" type="date" name="generateTime"
               [(ngModel)]="addForm.generateTime">
      </div>
      <div *ngIf="addForm.repetition!=='0'&&addForm.repetition!=='5'" class="form-group">
        <label>Email</label>
        <input #email1="ngModel" required="required" pattern=".*\@.*\..*" placeholder="Enter email to send report"
               class="form-control" type="email" name="email" [(ngModel)]="addForm.mailToSend">
        <small *ngIf="email1.touched&&email1.invalid" class="alert-danger p-1">Enter a valid email</small>
      </div>

      <div class="form-group" *ngIf="!preview">
        <label>Format</label>
        <select class="form-control" name="format" [(ngModel)]="addForm.format">
          <!--          <option value="0">PDF</option>-->
          <option value="1">HTML</option>
          <option value="2">XLS</option>
        </select>
      </div>

    </form>

  </div>
  <div class="modal-footer">
    <select name="generate" [(ngModel)]="addForm.repetition" class="form-control col-xl-5">
      <option value="5" *ngIf="preview">Preview Now</option>
      <option value="0" *ngIf="!preview">Generate Now</option>
      <option value="1" *ngIf="!preview">Daily</option>
      <option value="2" *ngIf="!preview">Weekly</option>
      <option value="3" *ngIf="!preview">Monthly</option>
      <option value="4" *ngIf="!preview">Select Date</option>
    </select>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button (click)="submitAddForm(this.report)" [disabled]="form.form.invalid||form.form.pristine"
            class="btn btn-outline-secondary"><i
      class="fa fa-cog"></i> {{preview ? 'Preview' : 'Generate'}}
    </button>

  </div>
</ng-template>
<ng-template #delete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title3">Are you sure?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3>Deleted Report cannot be undone !</h3>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="deleteObject()">Yes</button>
  </div>
</ng-template>

<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <h5 class="card-title mt-0 mb-0 header-title">Reports</h5>
        <div class="float-sm-right mt-3 mt-sm-0">
          <div class="task-search d-inline-block mb-3 mb-sm-0 mr-sm-3">
            <form>
              <div class="input-group">
                <input name="search" [(ngModel)]="search" type="text" class="form-control search-input"
                       placeholder="Search..."/>
                <span class="uil uil-search icon-search"></span>
                <div class="input-group-append">
                  <button (click)="addForm.repetition='1';preview=false;ngbModal.open(addReport)"
                          class="btn btn-soft-primary" type="button">
                    <i class='uil uil-plus-circle'></i> Add New
                  </button>
                  <button (click)="addForm.repetition='5';preview=true;ngbModal.open(addReport)"
                          class="btn btn-soft-secondary" type="button">
                    <i class='uil uil-presentation'></i>Preview Report
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-4">
      <table id="data-table" class="table table-hover table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">SN</th>
          <th scope="col">Name</th>
<!--          <th scope="col">Objects</th>-->
          <th scope="col">Report Type</th>
          <th scope="col">Report Repetition</th>
          <th scope="col">Email(To send)</th>
          <th scope="col">Next Report On</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr (contextmenu)="rightClick()" *ngFor="let item of pagedobjects;let i=index;">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
<!--          <td>{{item.objects.join(",")}}</td>-->
          <td>{{this.reportType[item.type]}}</td>
          <td>{{repitionType[Number(item.repetition)]}}</td>
          <td>{{item.mailToSend}}</td>
          <td>{{Date(item.nextUpdateOn)}}</td>
          <td><a
            (click)="deleteNow(item);ngbModal.open(delete)">&nbsp;<i
            class="fa fa-trash"></i></a></td>
        </tr>
        </tbody>
        <div *ngIf="pagedobjects.length===0" style="padding: 10px;">
          No reports found.
        </div>

      </table>
    </div>
    <br>
    <div>
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
      </ngb-pagination>
    </div>

  </div> <!-- end card-body-->
</div> <!-- end card-->
